import { CrudState } from "@/store/Crud/State";
export class State extends CrudState {
  pdfMimeType = "";
  pngMimeType = "";
  logoMimeType = "";
  pdfFileName = "";
  pngFileName = "";
  logoFileName = "";
  pdfSuccess = null;
  pngSuccess = null;
  logoSuccess = null;
  pdfItem: Record<string, any> = {};
  pngItem: Record<string, any> = {};
  logoItem: Record<string, any> = {};
  drivingSchoolSwitches = null;
  signatureStatuses: any = {};
  qrCodeUrl = "";
  driveBuzzOnboardingLinks: any = {};
  qrCodeUrlCourse = "";
}
