import { ActionTree } from "vuex";
import { State } from "./state";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import queryString from "query-string";
import { create, filter, findAll, findOne, update } from "@/store/Crud/functions/actions";

import { archive } from "@/store/Crud/functions/archived/actions";

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  findAll,
  update,
  archive,
};
