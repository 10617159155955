import axios from "@/utils/axios";
import { IS_LOADING, SET_DATA_LIST, SET_ERROR } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";

import { SET_FURTHER_EDUCATION_LIST } from "./types";

export const findAllBasicEducations = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get("/knowledge-areas?furtherEducation=false")
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findAllFurtherEducations = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get("/knowledge-areas?furtherEducation=true")
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_FURTHER_EDUCATION_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  findAll,
  filter,
  findAllBasicEducations,
  findAllFurtherEducations,
  findOne,
  update,
};
