import {
  SET_SELECTED_AUTOMATIC_PAYMENT_STUDENT,
  SET_SELECTED_CLASSIC_PAY_STUDENTS,
  SET_SELECTED_COST_BEARER_CLASSIC_PAY_STUDENTS,
  SET_SELECTED_FLASH_PAY_STUDENTS,
  SET_SELECTED_COST_BEARER_SINGLE_STUDENT,
} from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { IPaymentWorkflowStudent } from "@/interfaces/IPaymentWorkflowStudent";

export const setSelectedClassicPayStudents = async (store: any, data: Array<any>): Promise<void> => {
  store.commit(SET_SELECTED_CLASSIC_PAY_STUDENTS, data);
};

export const setSelectedAutomaticPaymentStudent = async (store: any, data: Array<any>): Promise<void> => {
  store.commit(SET_SELECTED_AUTOMATIC_PAYMENT_STUDENT, data);
};

export const setSelectedFlashPayStudents = async (store: any, data: Array<any>): Promise<void> => {
  store.commit(SET_SELECTED_FLASH_PAY_STUDENTS, data);
};

export const setSelectedCostBearerClassicPayStudents = async (store: any, data: Array<IPaymentWorkflowStudent>): Promise<void> => {
  store.commit(SET_SELECTED_COST_BEARER_CLASSIC_PAY_STUDENTS, data);
};

export const setSelectedCostBearerSingleStudent = async (store: any, data: Record<any, any>): Promise<void> => {
  store.commit(SET_SELECTED_COST_BEARER_SINGLE_STUDENT, data);
};

export default <ActionTree<State, any>>{
  setSelectedClassicPayStudents,
  setSelectedAutomaticPaymentStudent,
  setSelectedFlashPayStudents,
  setSelectedCostBearerClassicPayStudents,
  setSelectedCostBearerSingleStudent,
};
