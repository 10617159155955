import { Module, ModuleTree } from "vuex";
import Filter from "@/store/Abstract/Filter";
import { IDrivingLessonsPerStudentReport } from "@/interfaces/IDrivingLessonsPerStudentReport";
import { IRegistrationsPerDrivingSchoolReport } from "@/interfaces/IRegistrationsPerDrivingSchoolReport";
import { IAnsweredQuestionsPerDrivingSchoolReport } from "@/interfaces/IAnsweredQuestionsPerDrivingSchoolReport";
import { IReportTrainingDurationInDays } from "@/interfaces/IReportTrainingDurationInDays";

export default class Report implements Module<any, any> {
  namespaced = true;
  modules: ModuleTree<any>;

  constructor() {
    this.modules = {
      drivingLessonsPerStudentEducation: new Filter<IDrivingLessonsPerStudentReport>(),
      registrationsPerDrivingSchool: new Filter<IRegistrationsPerDrivingSchoolReport>(),
      answeredQuestionsPerDrivingSchool: new Filter<IAnsweredQuestionsPerDrivingSchoolReport>(),
      reportTrainingDurationInDays: new Filter<IReportTrainingDurationInDays>(),
      export: new Filter(),
    };
  }
}
