import i18n from "@/i18n";
import { MODULE_ACCOUNTING } from "@/constants/Permissions";

export default [
  {
    path: "/accounting",
    name: "Accounting",
    meta: {
      title: String(i18n.t("sidebar.accounting")),
      trans: "sidebar.accounting",
      permissions: [MODULE_ACCOUNTING],
    },
    component: () => import(/* webpackChunkName: "accounting" */ "@/views/Company/Accounting.vue"),
  },
];
