import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_TEST_ITEM, SET_DATA_LIST, SET_SUCCESS } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, del } from "@/store/Crud/functions/actions";

const URL = "/email-config";
const URL_VALIDATOR = "/email-config-validator";

export const test = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .put(URL_VALIDATOR, data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_TEST_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findEmailConfigsById = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`${URL}/user/${id}/email-configs`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  test,
  del,
  findEmailConfigsById,
};
