import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { findAll, findOne, update } from "@/store/Crud/functions/actions";
import { findOverviewInfo } from "@/store/PaymentWorkflow/actions";
import { AxiosError, AxiosResponse } from "axios";
import {
  SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_ERROR,
  SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_LOADING,
  SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_SUCCESS,
  SET_STUDENT_LIST,
  SET_STUDENT_LIST_ERROR,
  SET_STUDENT_LIST_LOADING,
  SET_STUDENT_LIST_SUCCESS,
  SET_STUDENT_TOTAL,
} from "@/store/PaymentWorkflow/types";
import axios from "@/utils/axios";

const URL = "live-pay";
const URL_PAYMENT_WORKFLOW = "/payment-workflows";

export const findAllStudentList = async (store: ActionContext<any, any>, filter: Record<any, any>): Promise<void> => {
  store.commit(SET_STUDENT_LIST_LOADING, true);
  store.commit(SET_STUDENT_LIST_SUCCESS, false);
  store.commit(SET_STUDENT_LIST_ERROR, null);

  return new Promise((resolve, reject) => {
    axios
      .post(`${URL_PAYMENT_WORKFLOW}/paged-students-list/filter`, filter)
      .then((response: AxiosResponse) => {
        resolve({ data: response.data?.data ?? [], total: response.data?.total ?? 0 });
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  })
    .then((data: any) => {
      const educationIds = data.data.map((student: any) => student.studentEducationId);
      return new Promise((resolve, reject) => {
        axios
          .post(`${URL_PAYMENT_WORKFLOW}/live-pay-payment-status`, educationIds)
          .then((response: AxiosResponse) => {
            const newData = data.data.map((student: any) => {
              return {
                statusId: response.data?.[student.studentEducationId],
                ...student,
              };
            });
            resolve({ data: newData, total: data.total });
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      });
    })
    .then((students: any) => {
      store.commit(SET_STUDENT_LIST_LOADING, false);
      store.commit(SET_STUDENT_LIST, students.data);
      store.commit(SET_STUDENT_TOTAL, students.total);
      store.commit(SET_STUDENT_LIST_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_STUDENT_LIST_LOADING, false);
      store.commit(SET_STUDENT_LIST_ERROR, error);
    });
};

export const livePayPaymentUpdateStatus = async (store: any, livePayPaymentId: number): Promise<void> => {
  store.commit(SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_LOADING, true);
  store.commit(SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_SUCCESS, false);
  store.commit(SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_ERROR, null);
  return await axios
    .post(`${URL}/payment/update-status/${livePayPaymentId}`)
    .then(() => {
      store.commit(SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_LOADING, false);
    });
};

export default <ActionTree<State, any>>{
  findAll,
  findAllStudentList,
  findOverviewInfo,
  livePayPaymentUpdateStatus,
  findOne,
  update,
};
