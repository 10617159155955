import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId, getDeleted } from "@/store/Crud/functions/getters";

export const getEmailExists = (state: State) => state.emailExists;
export const getBlob = (state: State) => state.blob;
export const getMimeType = (state: State) => state.mimeType;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getDeleted,
  getEmailExists,
  getBlob,
  getMimeType,
};
