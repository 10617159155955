import { GetterTree } from "vuex";
import { State } from "./state";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId, getDeleted } from "@/store/Crud/functions/getters";
import { getArchiveSuccess } from "@/store/Crud/functions/archived/getters";

import {
  getBookingSuccess,
  isBookingLoading,
  getUploadSuccess,
  isUploadLoading,
  getExamStatusSuccess,
  isExamStatusLoading,
  getParticipantsListSuccess,
  isParticipantsListLoading,
  getReadySuccess,
  getReadyIsLoading,
  getReadyError,
  getRemoveParticipantSuccess,
  getEducationStatusInfoData,
} from "@/store/Exam/getters";

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getArchiveSuccess,
  getBookingSuccess,
  isBookingLoading,
  getUploadSuccess,
  isUploadLoading,
  getExamStatusSuccess,
  isExamStatusLoading,
  getParticipantsListSuccess,
  isParticipantsListLoading,
  getReadySuccess,
  getReadyIsLoading,
  getReadyError,
  getRemoveParticipantSuccess,
  getDeleted,
  getEducationStatusInfoData,
};
