import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { SET_ERROR, SET_DATA_ITEM_COST_BEARER, IS_LOADING_COST_BEARER } from "./types";
import axios from "@/utils/axios";

const URL = "/balance";

export const findSaldoByStudentIdCostBearer = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING_COST_BEARER, true);
  return await axios
    .get(`${URL}/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING_COST_BEARER, false);
      store.commit(SET_DATA_ITEM_COST_BEARER, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING_COST_BEARER, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  findSaldoByStudentIdCostBearer,
};
