import { $axios } from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_DATA_ITEM } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import SessionStorageService from "@/services/SessionStorageService";
import router from "@/router";
import UserService from "@/services/UserService";
import jwtDecode from "jwt-decode";
import { IJwtToken } from "@/interfaces/IJwtToken";
import { AxiosError, AxiosResponse } from "axios";

/**
 * @param store
 * @param credentials
 * @returns {Promise<void>}
 */
export const signIn = async (store: any, credentials: Record<string, any>): Promise<void> => {
  let url = "/generate-token";
  if ("token" in credentials) { // token auth ?
    url = "generate-token-for-support-user";
  }
  return await $axios
    .post(url, credentials)
    .then(async ({ data }) => {
      const decodeToken: IJwtToken = jwtDecode(data.token);
      UserService.setTokenInfo(decodeToken);
      UserService.setUser(data.instructor);
      SessionStorageService.setToLocalStorage("token", data.token);
      UserService.setUserSettings(data.userSettings);
      store.commit(SET_DATA_ITEM, data);
      await $axios
        .get(`/roles/permissions?drivingSchoolId=${decodeToken.drivingSchoolId}&roleId=${decodeToken.roleId}`)
        .then((response: AxiosResponse) => {
          UserService.setPermissions(response.data);
          router.push({ name: "Dashboard" });
        })
        .catch((error: AxiosError) => {
          store.commit(SET_ERROR, error.response?.data);
        });
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR, response.data);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};

/**
 * @returns {Promise<void>}
 */
export const signOut = async (store: ActionContext<any, any>) => {
  store.commit("branch/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("practical-exam/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("theory-exam/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("broadcast/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("calendar/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("cost-bearer/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("calendar/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("driving-school/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("instructor/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("mailbox/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("messaging/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("classic-pay/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("flash-pay/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("live-pay/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("payment-document/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("price-list/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("registrations/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("theory-lesson/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("todo/localFilter/SET_LOCAL_FILTER", null, { root: true });
  store.commit("vehicle/localFilter/SET_LOCAL_FILTER", null, { root: true });
  UserService.clearUser();
  await router.push({ name: "Login" });
};
export default <ActionTree<State, any>>{
  signIn,
  signOut,
};
