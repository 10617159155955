import axios from "@/utils/axios";
import {
  IS_LOADING,
  SET_ERROR,
  SET_DATA_ITEM,
  SET_SUCCESS,
  SET_EDUCATIONS_LIST,
  SET_SALDO_LIST,
  SET_SALDO_LIST_LOADING,
  SET_MIME_TYPE,
  SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE,
  SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_LOADING,
  SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_SUCCESS,
  SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_ERROR,
} from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll, uploadFile, setSelectedRowName } from "@/store/Crud/functions/actions";
import { archive } from "@/store/Crud/functions/archived/actions";
import { AxiosResponse } from "axios";
import i18n from "@/i18n";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
const messageDispatcher = new MessageDispatcher();

const URL = "/cost-bearers";
const DOCUMENT_URL = "/cost-bearer-documents";

export const findCostBearerDocument = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${DOCUMENT_URL}/${id}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const findByStudentId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`${URL}/byStudentId/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findEducationByCostBearerId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`${URL}/student-education-by-cost-bearer/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_EDUCATIONS_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findSaldoByCostBearerId = async (store: any, id: number): Promise<void> => {
  store.commit(SET_SALDO_LIST_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`${URL}/balance/${id}`)
    .then(({ data }) => {
      store.commit(SET_SALDO_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR, response.data);
    })
    .finally(() => {
      store.commit(SET_SALDO_LIST_LOADING, false);
    });
};

export const generateClassicPayCustomBookingInvoice = async (
  store: any,
  options: {
    costBearerId: number;
    costBearerCustomBookingIds: Array<number>;
  }
): Promise<void> => {
  store.commit(SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_LOADING, true);
  store.commit(SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_SUCCESS, false);
  store.commit(SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_ERROR, null);
  return await axios
    .post(`/payment-documents/create-cost-bearer-invoice`, options)
    .then(({ data }) => {
      messageDispatcher.dispatchSuccessMessage(store, String(i18n.t("messages.save_success", { item: null })));
      store.commit(SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE, data);
      store.commit(SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_ERROR, response.data);
    })
    .finally(() => {
      store.commit(SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_LOADING, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  findByStudentId,
  findEducationByCostBearerId,
  findSaldoByCostBearerId,
  uploadFile,
  archive,
  findCostBearerDocument,
  setSelectedRowName,
  generateClassicPayCustomBookingInvoice,
};
