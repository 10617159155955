import { State } from "./state";
import { GetterTree } from "vuex";

export const getError = (state: State): Record<any, any> | null => state.error;
export const getSuccess = (state: State) => state.success;
export const getIsLoading = (state: State) => state.isLoading;
export const getData = (state: State) => state.data;
export const getTotal = (state: State) => state.total;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getSuccess,
  getData,
  getTotal,
};
