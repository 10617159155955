import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_DATA_ITEM, SET_DATA_LIST, SET_SUCCESS, SET_CHECKLIST_DATA_ITEM } from "./types";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { ActionTree } from "vuex";
import { State } from "./state";

const CHECKLIST_URL = "/checklist/template";
const CHECKLIST_CHECK = "/checklist/check";
const CHECKLIST_BY_STUDENT = "/checklist/by-student-education";
const CHECKLIST_BY_COST_BEARER_URL = "/checklist/by-cost-bearer";
const CHECKLIST_COST_BEARER_TEMPLATE_URL = "/checklist/template/cost-bearer";
const CHECKLIST_COST_BEARER_CHECK = "/checklist/cost-bearer/check";

export const findCheckListsByCostBearerId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`${CHECKLIST_BY_COST_BEARER_URL}/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_CHECKLIST_DATA_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const createByCostBearerId = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${CHECKLIST_COST_BEARER_TEMPLATE_URL}/${options.costBearerId}/${options.checkListTemplateId}`, options.data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const createByStudentId = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${CHECKLIST_URL}/${options.studentId}/${options.checklistTemplateId}`, options.data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const removeTemplateByStudentId = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${CHECKLIST_URL}/${options.studentId}/${options.checklistTemplateId}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const removeTemplateByCostBearerId = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${CHECKLIST_COST_BEARER_TEMPLATE_URL}/${options.costBearerId}/${options.checkListTemplateId}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const updateCheckedById = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .put(`${CHECKLIST_CHECK}/${options.id}?checked=${options.checked}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const updateCostBearerCheckedById = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .put(`${CHECKLIST_COST_BEARER_CHECK}/${options.id}?checked=${options.checked}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  createByStudentId,
  removeTemplateByStudentId,
  updateCheckedById,
  findCheckListsByCostBearerId,
  createByCostBearerId,
  removeTemplateByCostBearerId,
  updateCostBearerCheckedById,
};
