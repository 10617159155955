import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";

export function setMessage(store: ActionContext<State, any>, data: any) {
  store.commit("MESSAGE", data);
}
export function resetSnackbar(store: ActionContext<State, any>) {
  store.commit("MESSAGE", null);
}
export function setLoading(store: ActionContext<State, any>, loading: boolean) {
  store.commit("LOADING", loading);
}

export default <ActionTree<State, any>>{
  setMessage,
  resetSnackbar,
  setLoading,
};
