import { State } from "./state";
import { MutationTree } from "vuex";

import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import {
  SET_EMAIL_FILTER,
  SET_MAILBOX_FILTER,
  SET_MAILBOX_DATA,
  SET_SWITCH_MAIL_STATUS,
  SET_SELECTED_TARGET_EMAILS,
  SET_SELECTED_EMAIL_CONTENT,
  SET_UPDATE_MAILBOX_LIST,
  SET_TOTAL_ITEMS,
  SET_EMAIL_TEMPLATES,
  SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_ERROR,
  SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_SUCCESS,
  SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING,
  SET_EMAIL_TEMPLATES_BY_CATEGORY,
  SET_SELECTED_EMAIL_TEMPLATE,
  SET_TEMPLATE_CATEGORIES,
  SET_PLACEHOLDERS,
  SET_STUDENT,
  SET_BLOB,
  SET_MIME_TYPE,
  SET_COST_BEARER,
  SET_ATTACH_EXAM_DOCUMENT,
} from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,

  [SET_EMAIL_FILTER](state: State, emails: Array<any>) {
    const emailFilter = [...emails, { id: "marked", email: "Markiert" }];
    Object.assign(state, { emailFilter });
  },
  [SET_MAILBOX_FILTER](state: State, mailboxFilter: Array<any>) {
    Object.assign(state, { mailboxFilter });
  },

  [SET_MAILBOX_DATA](state: State, mailboxData: Array<any>) {
    state.mailboxData = mailboxData;
  },
  [SET_SWITCH_MAIL_STATUS](state: State, { emailId, action }) {
    const index = state.mailboxData.findIndex((mail) => mail.id === emailId);
    if (index !== undefined && action) {
      switch (action) {
        case "read_0":
          state.mailboxData[index].read = false;
          break;
        case "read_1":
          state.mailboxData[index].read = true;
          break;
        case "marked_1":
          state.mailboxData[index].marked = true;
          break;
        case "marked_0":
          state.mailboxData[index].marked = false;
          break;
      }
    }
  },
  [SET_UPDATE_MAILBOX_LIST](state: State, payload) {
    state.mailboxData = state.mailboxData.filter((mail) => mail.id !== payload);
  },
  [SET_SELECTED_TARGET_EMAILS](state: State, payload) {
    state.selectedTargetEmails = payload;
  },
  [SET_SELECTED_EMAIL_CONTENT](state: State, payload) {
    state.selectedEmailContent = payload;
  },
  [SET_TOTAL_ITEMS](state: State, payload) {
    state.totalItems = payload;
  },
  [SET_EMAIL_TEMPLATES_BY_CATEGORY](state: State, payload) {
    state.emailTemplatesByCategory = payload;
  },
  [SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING](state: State, payload: boolean) {
    state.forceSyncByEmailConfigIdsLoading = payload;
  },
  [SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_SUCCESS](state: State, payload: boolean) {
    state.forceSyncByEmailConfigIdsSuccess = payload;
  },
  [SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_ERROR](state: State, payload: Record<any, any> | null) {
    state.forceSyncByEmailConfigIdsError = payload;
  },
  [SET_EMAIL_TEMPLATES](state: State, payload) {
    state.emailTemplates = payload;
  },
  [SET_SELECTED_EMAIL_TEMPLATE](state: State, payload) {
    state.selectedEmailTemplate = payload;
  },
  [SET_TEMPLATE_CATEGORIES](state: State, payload) {
    state.emailTemplateCategories = payload;
  },
  [SET_PLACEHOLDERS](state: State, payload) {
    state.placeholders = payload;
  },
  [SET_STUDENT](state: State, student: any) {
    Object.assign(state, { student });
  },
  [SET_BLOB](state: State, blob: any) {
    Object.assign(state, { blob });
  },
  [SET_MIME_TYPE](state: State, mimeType: string) {
    Object.assign(state, { mimeType });
  },
  [SET_COST_BEARER](state: State, costBearer: any) {
    Object.assign(state, { costBearer });
  },
  [SET_ATTACH_EXAM_DOCUMENT](state: State, attachExamDocument: any) {
    Object.assign(state, { attachExamDocument });
  },
};
