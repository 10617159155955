import i18n from "@/i18n";
import { MODULE_COMPANY } from "@/constants/Permissions";

export default [
  {
    path: "/products",
    name: "Products",
    meta: {
      title: String(i18n.tc("general.product", 2)),
      trans: "sidebar.products",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "products" */ "@/views/Product/Products.vue"),
  },
];
