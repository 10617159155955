import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { findAll, create, update } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";

const URL = "live-pay-mandate";

export const revoke = async (store: ActionContext<any, any>, mandateId: number): Promise<void> => {
  store.commit("SET_MANDATE_REVOKE_LOADING", true);
  store.commit("SET_MANDATE_REVOKE_SUCCESS", false);
  store.commit("SET_MANDATE_REVOKE_ERROR", null);
  return await axios
    .get(`${URL}/revoke-mandate/${mandateId}`)
    .then((response: AxiosResponse) => {
      store.commit("SET_MANDATE_REVOKE_RESPONSE", response.data);
      store.commit("SET_MANDATE_REVOKE_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("SET_MANDATE_REVOKE_ERROR", error);
    })
    .finally(() => {
      store.commit("SET_MANDATE_REVOKE_LOADING", false);
    });
};

export const findByStudentEducation = async (store: ActionContext<any, any>, studentEducationId: number): Promise<void> => {
  store.commit("SET_MANDATE_BY_STUDENT_EDUCATION_LOADING", true);
  store.commit("SET_MANDATE_BY_STUDENT_EDUCATION_SUCCESS", false);
  store.commit("SET_MANDATE_BY_STUDENT_EDUCATION_ERROR", null);
  return await axios
    .get(`${URL}/by-student-education/${studentEducationId}`)
    .then((response: AxiosResponse) => {
      store.commit("SET_MANDATE_BY_STUDENT_EDUCATION_RESPONSE", response.data);
      store.commit("SET_MANDATE_BY_STUDENT_EDUCATION_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("SET_MANDATE_BY_STUDENT_EDUCATION_ERROR", error);
    })
    .finally(() => {
      store.commit("SET_MANDATE_BY_STUDENT_EDUCATION_LOADING", false);
    });
};
export const findByStudent = async (store: ActionContext<any, any>, studentId: number): Promise<void> => {
  store.commit("SET_MANDATE_BY_STUDENT_LOADING", true);
  store.commit("SET_MANDATE_BY_STUDENT_SUCCESS", false);
  store.commit("SET_MANDATE_BY_STUDENT_ERROR", null);
  return await axios
    .get(`${URL}/by-student/${studentId}`)
    .then((response: AxiosResponse) => {
      store.commit("SET_MANDATE_BY_STUDENT_RESPONSE", response.data);
      store.commit("SET_MANDATE_BY_STUDENT_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("SET_MANDATE_BY_STUDENT_ERROR", error);
    })
    .finally(() => {
      store.commit("SET_MANDATE_BY_STUDENT_LOADING", false);
    });
};

export default <ActionTree<State, any>>{
  findAll,
  create,
  revoke,
  findByStudentEducation,
  findByStudent,
  update,
};
