import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";

export function setContent(store: ActionContext<State, any>, content: any) {
  store.commit("SET_CONTENT", content);
}

export function setStatus(store: ActionContext<State, any>, status: boolean) {
  store.commit("SET_STATUS", status);
}

export default <ActionTree<State, any>>{
  setContent,
  setStatus,
};
