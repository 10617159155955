import { ActionContext } from "vuex";
import { CrudState } from "@/store/Crud/State";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ArchiveActions from "@/store/Crud/classes/ArchiveActions";

export const archiveActions: ArchiveActions = new ArchiveActions();

export async function archive(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  await archiveActions.archive(store, options);
}
