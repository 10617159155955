import { State } from "./state";
import { GetterTree } from "vuex";

export const getSelectedClassicPayStudents = (state: State): Array<any> => state.selectedClassicPayStudents;

export const getSelectedFlashPayStudents = (state: State): Record<any, any> => state.selectedFlashPayStudents;

export const getSelectedAutomaticPaymentStudent = (state: State): Record<any, any> => state.selectedAutomaticPaymentStudent;
export const getSelectedCostBearerClassicPayStudents = (state: State): Record<any, any> => state.selectedCostBearerClassicPayStudents;
export const getSelectedCostBearerSingleStudent = (state: State): Record<any, any> => state.selectedCostBearerSingleStudent;

export default <GetterTree<State, any>>{
  getSelectedClassicPayStudents,
  getSelectedAutomaticPaymentStudent,
  getSelectedFlashPayStudents,
  getSelectedCostBearerClassicPayStudents,
  getSelectedCostBearerSingleStudent,
};
