export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";

export const SET_DOCUMENTS_IN_PROCESSING_ERROR = "SET_DOCUMENTS_IN_PROCESSING_ERROR";
export const SET_DOCUMENTS_IN_PROCESSING_SUCCESS = "SET_DOCUMENTS_IN_PROCESSING_SUCCESS";
export const SET_DOCUMENTS_IN_PROCESSING_LOADING = "SET_DOCUMENTS_IN_PROCESSING_LOADING";
export const SET_DOCUMENTS_IN_PROCESSING = "SET_DOCUMENTS_IN_PROCESSING";

export const SET_ATTACH_DOCUMENTS_IN_PROCESSING_ERROR = "SET_ATTACH_DOCUMENTS_IN_PROCESSING_ERROR";
export const SET_ATTACH_DOCUMENTS_IN_PROCESSING_SUCCESS = "SET_ATTACH_DOCUMENTS_IN_PROCESSING_SUCCESS";
export const SET_ATTACH_DOCUMENTS_IN_PROCESSING_LOADING = "SET_ATTACH_DOCUMENTS_IN_PROCESSING_LOADING";

export const SET_DOCUMENTS_IN_PROCESSING_DELETE_ERROR = "SET_DOCUMENTS_IN_PROCESSING_DELETE_ERROR";
export const SET_DOCUMENTS_IN_PROCESSING_DELETE_SUCCESS = "SET_DOCUMENTS_IN_PROCESSING_DELETE_SUCCESS";
export const SET_DOCUMENTS_IN_PROCESSING_DELETE_LOADING = "SET_DOCUMENTS_IN_PROCESSING_DELETE_LOADING";
