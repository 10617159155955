import { State } from "./state";
import { GetterTree } from "vuex";
import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId, getDataItemLoading } from "@/store/Crud/functions/getters";

export const getGroups = (state: State) => state.groups;
export const getStatuses = (state: State) => state.statuses;
export const getPriorities = (state: State) => state.priorities;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getGroups,
  getStatuses,
  getPriorities,
  getDataItemLoading,
};
