import i18n from "@/i18n";
import { MODULE_EXAM } from "@/constants/Permissions";

export default [
  {
    path: "/practical-exams",
    name: "PracticalExam",
    meta: {
      title: String(i18n.t("sidebar.practical_exams")),
      trans: "sidebar.practical_exams",
      permissions: [MODULE_EXAM],
    },
    component: () => import(/* webpackChunkName: "practical-exam" */ "@/views/Exam/PracticalExam/PracticalExam.vue"),
  },
];
