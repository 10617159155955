import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
///users/with-writable-calendars-by-current-user

///users/with-readable-calendars-by-current-user
export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
};
