import axios from "@/utils/axios";
import {
  AUTOMATIC_PAYMENT_STATE_ERROR,
  AUTOMATIC_PAYMENT_STATE_IS_LOADING,
  AUTOMATIC_PAYMENT_STATE_SUCCESS,
  FINISHED_EDUCATION_STATE_ERROR,
  FINISHED_EDUCATION_STATE_IS_LOADING,
  FINISHED_EDUCATION_STATE_SUCCESS,
  IS_LOADING,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_ERROR,
  SET_QUESTION_VERSION,
  SET_DRIVE_BUZZ,
  SET_DATA_ITEM_COST_BEARER,
} from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
// @ts-ignore
import queryString from "query-string";
import { SET_SUCCESS } from "@/store/Student/types";
import { SET_DATA_LIST_COST_BEARER } from "@/store/Appointments/types";
import i18n from "@/i18n";

const URL = "/student-educations";

export const toggleDriveBuzz = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}/drive-buzz-enabled?id=${options.id}&driveBuzzEnabled=${options.isEnabled}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DRIVE_BUZZ, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findByStudent = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/student/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findEducationsByStudentCostBearer = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/student/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM_COST_BEARER, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findVersionByEducationId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/${id}/theory-questions-version`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_QUESTION_VERSION, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const changeFinishedEducationState = async (store: any, options: ICrudOptions): Promise<void> => {
  const queryBuilder = queryString.stringify(options.params);
  store.commit(FINISHED_EDUCATION_STATE_IS_LOADING, true);
  store.commit(FINISHED_EDUCATION_STATE_SUCCESS, false);
  return await axios
    .post(`${URL}/change-finished-education-state?${queryBuilder}`)
    .then(({ data }) => {
      store.commit(FINISHED_EDUCATION_STATE_IS_LOADING, false);
      store.commit(FINISHED_EDUCATION_STATE_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(FINISHED_EDUCATION_STATE_IS_LOADING, false);
      store.commit(FINISHED_EDUCATION_STATE_ERROR, response.data);
    });
};

export const changeAutomaticPaymentState = async (store: any, options: ICrudOptions): Promise<void> => {
  const queryBuilder = queryString.stringify(options.params);
  store.commit(AUTOMATIC_PAYMENT_STATE_IS_LOADING, true);
  store.commit(AUTOMATIC_PAYMENT_STATE_SUCCESS, false);
  return await axios
    .post(`${URL}/change-automatic-payment-state?${queryBuilder}`)
    .then(({ data }) => {
      store.commit(AUTOMATIC_PAYMENT_STATE_IS_LOADING, false);
      store.commit(AUTOMATIC_PAYMENT_STATE_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(AUTOMATIC_PAYMENT_STATE_IS_LOADING, false);
      store.commit(AUTOMATIC_PAYMENT_STATE_ERROR, response.data);
    });
};
export const resetEducationQuestionsByStudentEducationId = async ({ commit, dispatch }: any, studentEducationId: number): Promise<void> => {
  const queryBuilder = queryString.stringify({ studentEducationId });
  const text = "students.question_versions_reset_message";
  const placeholder = "";
  commit(IS_LOADING, true);
  const url = "student-question-answers/reset-questions?" + queryBuilder;
  return await axios
    .delete(url)
    .then(({ data }) => {
      commit("IS_LOADING", false);
      commit(SET_SUCCESS, true);
      const type = "success";
      dispatch(
        "snackbar/setMessage",
        {
          text: String(i18n.t(text, { placeholder: String(i18n.t(placeholder)) })),
          type,
        },
        { root: true }
      );
    })
    .catch(() => {
      const type = "error";
      commit("IS_LOADING", false);
      commit(SET_ERROR, true);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findByStudent,
  changeFinishedEducationState,
  changeAutomaticPaymentState,
  findVersionByEducationId,
  findAll,
  toggleDriveBuzz,
  findEducationsByStudentCostBearer,
  resetEducationQuestionsByStudentEducationId,
};
