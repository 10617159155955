import { Module, ModuleTree } from "vuex";
import { State } from "./state";
import Mutations from "./mutations";
import Getters from "./getters";
import Actions from "./actions";
import LocalFilter from "@/store/Abstract/LocalFilter";

export default class CostBearer implements Module<State, any> {
  namespaced = true;
  state: State;
  mutations = Mutations;
  getters = Getters;
  actions = Actions;
  modules: ModuleTree<any>;

  constructor() {
    this.state = new State();
    this.modules = {
      localFilter: new LocalFilter(),
      searchLocalFilter: new LocalFilter(),
    };
  }
}
