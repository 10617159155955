import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import {
  SET_UPLOAD_STUDENT_SIGNATURE_ERROR,
  SET_UPLOAD_STUDENT_SIGNATURE_SUCCESS,
  SET_UPLOAD_STUDENT_SIGNATURE_LOADING,
  SET_UPLOAD_PARENT_SIGNATURE_ERROR,
  SET_UPLOAD_PARENT_SIGNATURE_SUCCESS,
  SET_UPLOAD_PARENT_SIGNATURE_LOADING,
  SET_SIGNED_ERROR,
  SET_SIGNED_SUCCESS,
  SET_SIGNED_LOADING,
  SET_SIGNED_RESPONSE,
  SET_ADDONS_CHECKBOX_TEXT_LOADING,
  SET_ADDONS_CHECKBOX_TEXT_SUCCESS,
  SET_ADDONS_CHECKBOX_TEXT_ERROR,
  SET_ADDONS_CHECKBOX_TEXT_RESPONSE,
  SET_GDPR_URL_CHECKBOX_TEXT_LOADING,
  SET_GDPR_URL_CHECKBOX_TEXT_SUCCESS,
  SET_GDPR_URL_CHECKBOX_TEXT_ERROR,
  SET_GDPR_URL_CHECKBOX_TEXT_RESPONSE,
} from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_UPLOAD_STUDENT_SIGNATURE_ERROR](state: State, uploadStudentSignatureError: Record<any, any> | null) {
    state.uploadStudentSignatureError = uploadStudentSignatureError;
  },

  [SET_UPLOAD_STUDENT_SIGNATURE_SUCCESS](state: State, uploadStudentSignatureSuccess: boolean) {
    state.uploadStudentSignatureSuccess = uploadStudentSignatureSuccess;
  },

  [SET_UPLOAD_STUDENT_SIGNATURE_LOADING](state: State, uploadStudentSignatureLoading: boolean) {
    state.uploadStudentSignatureLoading = uploadStudentSignatureLoading;
  },

  [SET_UPLOAD_PARENT_SIGNATURE_ERROR](state: State, uploadParentSignatureError: Record<any, any> | null) {
    state.uploadParentSignatureError = uploadParentSignatureError;
  },

  [SET_UPLOAD_PARENT_SIGNATURE_SUCCESS](state: State, uploadParentSignatureSuccess: boolean) {
    state.uploadParentSignatureSuccess = uploadParentSignatureSuccess;
  },

  [SET_UPLOAD_PARENT_SIGNATURE_LOADING](state: State, uploadStudentSignatureLoading: boolean) {
    state.uploadParentSignatureLoading = uploadStudentSignatureLoading;
  },

  [SET_SIGNED_ERROR](state: State, signedError: Record<any, any> | null) {
    state.signedError = signedError;
  },

  [SET_SIGNED_SUCCESS](state: State, signedSuccess: boolean) {
    state.signedSuccess = signedSuccess;
  },

  [SET_SIGNED_LOADING](state: State, signedLoading: boolean) {
    state.signedLoading = signedLoading;
  },

  [SET_SIGNED_RESPONSE](state: State, signedResponse: File | null) {
    state.signedResponse = signedResponse;
  },

  [SET_ADDONS_CHECKBOX_TEXT_LOADING](state: State, addonsCheckboxTextLoading: boolean) {
    state.addonsCheckboxTextLoading = addonsCheckboxTextLoading;
  },
  [SET_ADDONS_CHECKBOX_TEXT_SUCCESS](state: State, addonsCheckboxTextSuccess: boolean) {
    state.addonsCheckboxTextSuccess = addonsCheckboxTextSuccess;
  },
  [SET_ADDONS_CHECKBOX_TEXT_ERROR](state: State, addonsCheckboxTextError: Record<any, any> | null) {
    state.addonsCheckboxTextError = addonsCheckboxTextError;
  },
  [SET_ADDONS_CHECKBOX_TEXT_RESPONSE](state: State, addonsCheckboxTextResponse: string | null) {
    state.addonsCheckboxTextResponse = addonsCheckboxTextResponse;
  },

  [SET_GDPR_URL_CHECKBOX_TEXT_LOADING](state: State, gdprUrlCheckboxTextLoading: boolean) {
    state.gdprUrlCheckboxTextLoading = gdprUrlCheckboxTextLoading;
  },
  [SET_GDPR_URL_CHECKBOX_TEXT_SUCCESS](state: State, gdprUrlCheckboxTextSuccess: boolean) {
    state.gdprUrlCheckboxTextSuccess = gdprUrlCheckboxTextSuccess;
  },
  [SET_GDPR_URL_CHECKBOX_TEXT_ERROR](state: State, gdprUrlCheckboxTextError: Record<any, any> | null) {
    state.gdprUrlCheckboxTextError = gdprUrlCheckboxTextError;
  },
  [SET_GDPR_URL_CHECKBOX_TEXT_RESPONSE](state: State, gdprUrlCheckboxTextResponse: string | null) {
    state.gdprUrlCheckboxTextResponse = gdprUrlCheckboxTextResponse;
  },
};
