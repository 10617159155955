import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update } from "@/store/Crud/functions/actions";
import {
  IS_LOADING,
  SET_DATA_ITEM,
  SET_ERROR,
  SET_SUCCESS,
  SET_PDF_MIME_TYPE,
  SET_PNG_MIME_TYPE,
  SET_LOGO_MIME_TYPE,
  SET_PDF_SUCCESS,
  SET_PNG_SUCCESS,
  SET_LOGO_SUCCESS,
  SET_PDF_ITEM,
  SET_PNG_ITEM,
  SET_LOGO_ITEM,
  // SET_PDF_FILENAME,
  // SET_PNG_FILENAME,
  // SET_LOGO_FILENAME,
  SET_DRIVING_SCHOOL_SWITCHES,
  SET_QR_CODE_URL,
  SET_DRIVE_BUZZ_ONBOARDING_LINKS,
  SET_COURSE_QR_CODE_URL,
} from "@/store/DrivingSchool/types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

const URL = "/driving-schools";
const URL_LETTERHEAD = "/driving-school-documents/letterhead/current";
const URL_SIGNATURE = "/driving-school-documents/signature/current";
const URL_LOGO = "/driving-school-documents/logo/current";
const URL_SWITCHES = "/driving-schools/current/switches";

export const uploadPdfDocument = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_PDF_SUCCESS, false);
  store.commit(SET_ERROR, null);
  return await axios
    .post(options.resource, options.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_PDF_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error);
      store.commit(SET_PDF_SUCCESS, false);
    });
};

export const uploadPngDocument = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_PNG_SUCCESS, false);
  store.commit(SET_ERROR, null);
  return await axios
    .post(options.resource, options.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_PNG_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error);
      store.commit(SET_PNG_SUCCESS, false);
    });
};

export const uploadLogoDocument = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_LOGO_SUCCESS, false);
  store.commit(SET_ERROR, null);
  return await axios
    .post(options.resource, options.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_LOGO_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error);
      store.commit(SET_LOGO_SUCCESS, false);
    });
};

export const findDrivingSchoolLetterHead = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_PDF_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL_LETTERHEAD}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_PDF_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_PDF_ITEM, blob);
    })
    .catch((response) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findDrivingSchoolSignature = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_PNG_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL_SIGNATURE}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_PNG_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_PNG_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findDrivingSchoolLogo = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_LOGO_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL_LOGO}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_LOGO_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_LOGO_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response?.data);
    });
};

export const findDrivingSchoolSwitches = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(URL_SWITCHES)
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      store.commit(SET_DRIVING_SCHOOL_SWITCHES, response.data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const findCurrentDrivingSchool = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/current`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

const fetchAllSignatureStatuses = ({ commit }: any) => {
  const url = "/driving-school-documents/basic-documents-availability";
  commit(IS_LOADING, true);

  return axios
    .get(url)
    .then(({ data }: AxiosResponse) => {
      commit(IS_LOADING, false);

      if (data) {
        commit("SET_SIGNATURE_STATUSES", data);
      }
    })
    .catch(({ response }) => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, response.data);
    });
};

const findQRCodeUrl = ({ commit }: any): Promise<void> => {
  const url = "/driving-schools/current/drive-buzz-registration-url";
  commit(IS_LOADING, true);

  return axios
    .get(url)
    .then(({ data }: AxiosResponse) => {
      if (data) {
        commit(IS_LOADING, false);
        commit(SET_QR_CODE_URL, data);
      }
    })
    .catch(({ response }) => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, response.data);
    });
};

export const findQRCodeUrlCourse = async (store: any): Promise<void> => {
  const url = "/driving-schools/current/drive-buzz-registration-fastlane-url";
  store.commit(IS_LOADING, true);
  return axios
    .get(url)
    .then(({ data }: AxiosResponse) => {
      if (data) {
        store.commit(IS_LOADING, false);
        store.commit(SET_COURSE_QR_CODE_URL, data);
      }
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

const findDriveBuzzOnboardingLinks = ({ commit, state, getters }: any): Promise<void> => {
  const url = "/driving-schools/current/drive-buzz-onboarding-links";
  commit(IS_LOADING, true);

  return axios
    .get(url)
    .then(({ data }: AxiosResponse) => {
      if (data) {
        commit(IS_LOADING, false);
        commit(SET_DRIVE_BUZZ_ONBOARDING_LINKS, data);
      }
      return data;
    })
    .catch(({ response }) => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findDrivingSchoolLetterHead,
  findDrivingSchoolSignature,
  findDrivingSchoolLogo,
  uploadPdfDocument,
  uploadPngDocument,
  uploadLogoDocument,
  findDrivingSchoolSwitches,
  findCurrentDrivingSchool,
  fetchAllSignatureStatuses,
  findQRCodeUrl,
  findDriveBuzzOnboardingLinks,
  findQRCodeUrlCourse,
};
