import i18n from "@/i18n";

export default [
  {
    path: "/registration",
    name: "Registration",
    meta: {
      title: String(i18n.t("sidebar.onboarding")),
      trans: "sidebar.onboarding",
    },
    component: () => import(/* webpackChunkName: "registration" */ "@/views/Communication/Registration.vue"),
  },
  {
    path: "/email",
    name: "EmailClient",
    meta: {
      title: String(i18n.t("auth.email")),
      trans: "auth.email",
    },
    component: () => import(/* webpackChunkName: "email" */ "@/views/Communication/EmailClient.vue"),
  },
  {
    path: "/email-templates",
    name: "EmailTemplates",
    meta: {
      title: String(i18n.t("general.email_templates")),
      trans: "general.email_templates",
    },
    component: () => import(/* webpackChunkName: "email-templates" */ "@/views/Communication/EmailTemplates.vue"),
  },
  {
    path: "/broadcast",
    name: "Broadcast",
    meta: {
      title: String(i18n.t("sidebar.broadcast")),
      trans: "sidebar.broadcast",
    },
    component: () => import(/* webpackChunkName: "broadcast-list" */ "@/views/Communication/Broadcast.vue"),
  },
  {
    path: "/broadcast-templates",
    name: "BroadcastTemplates",
    meta: {
      title: String(i18n.t("sidebar.broadcast")),
      trans: "general.broadcast_templates",
      name: "broadcast-templates",
    },
    component: () => import(/* webpackChunkName: "broadcast-templates" */ "@/views/Communication/BroadcastTemplates.vue"),
  },

  {
    path: "/messaging",
    name: "Messaging",
    meta: {
      title: String(i18n.t("sidebar.messaging")),
      trans: "sidebar.messaging",
    },
    component: () => import(/* webpackChunkName: "messaging" */ "@/views/Communication/Messaging/Messaging.vue"),
  },
  {
    path: "/message-templates",
    name: "MessageTemplates",
    meta: {
      title: String(i18n.t("general.message_templates")),
      trans: "general.message_templates",
      name: "message-templates",
    },
    component: () => import(/* webpackChunkName: "message-templates" */ "@/views/Communication/MessageTemplates.vue"),
  },

  {
    path: "/automatic-messages",
    name: "AutomaticMessages",
    meta: {
      title: String(i18n.t("sidebar.automatic_messages")),
      trans: "sidebar.automatic_messages",
    },
    component: () => import(/* webpackChunkName: "automatic-messages" */ "@/views/Communication/AutomaticMessages/AutomaticMessages.vue"),
  },
];
