import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, update } from "@/store/Crud/functions/actions";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import axios from "@/utils/axios";
import { AxiosError } from "axios";
import {
  SET_DOCUMENTS_IN_PROCESSING_LOADING,
  IS_LOADING,
  SET_DATA_ITEM,
  SET_ERROR,
  SET_DOCUMENTS_IN_PROCESSING,
  SET_DOCUMENTS_IN_PROCESSING_SUCCESS,
  SET_DOCUMENTS_IN_PROCESSING_ERROR,
  SET_DOCUMENTS_IN_PROCESSING_DELETE_LOADING,
  SET_DOCUMENTS_IN_PROCESSING_DELETE_SUCCESS,
  SET_DOCUMENTS_IN_PROCESSING_DELETE_ERROR,
  SET_ATTACH_DOCUMENTS_IN_PROCESSING_ERROR,
  SET_ATTACH_DOCUMENTS_IN_PROCESSING_SUCCESS,
  SET_ATTACH_DOCUMENTS_IN_PROCESSING_LOADING,
  SET_SUCCESS,
} from "@/store/Document/types";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import i18n from "@/i18n";

const messageDispatcher = new MessageDispatcher();

export const uploadDocument = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit("IS_LOADING", true);
  store.commit("SET_SUCCESS", false);
  store.commit("SET_ERROR", null);
  return await axios
    .post(options.resource, options.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_SUCCESS", true);
      store.commit(SET_DATA_ITEM, data);
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.save_success", {
            item: null,
          })
        )
      );
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error);
      store.commit("SET_SUCCESS", false);
    });
};

export const documentsInProcessing = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(SET_DOCUMENTS_IN_PROCESSING_LOADING, true);
  store.commit(SET_DOCUMENTS_IN_PROCESSING_SUCCESS, null);
  store.commit(SET_DOCUMENTS_IN_PROCESSING_ERROR, null);
  return await axios
    .get(options.resource)
    .then(({ data }) => {
      store.commit(SET_DOCUMENTS_IN_PROCESSING, data);
      store.commit(SET_DOCUMENTS_IN_PROCESSING_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_DOCUMENTS_IN_PROCESSING_ERROR, error);
    })
    .finally(() => {
      store.commit(SET_DOCUMENTS_IN_PROCESSING_LOADING, false);
    });
};

export const documentsInProcessingDelete = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(SET_DOCUMENTS_IN_PROCESSING_DELETE_LOADING, true);
  store.commit(SET_DOCUMENTS_IN_PROCESSING_DELETE_SUCCESS, null);
  store.commit(SET_DOCUMENTS_IN_PROCESSING_DELETE_ERROR, null);
  return await axios
    .delete(options.resource)
    .then(() => {
      store.commit(SET_DOCUMENTS_IN_PROCESSING_DELETE_SUCCESS, true);
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.delete_success", {
            item: null,
          })
        )
      );
    })
    .catch((error: AxiosError) => {
      store.commit(SET_DOCUMENTS_IN_PROCESSING_DELETE_ERROR, error);
    })
    .finally(() => {
      store.commit(SET_DOCUMENTS_IN_PROCESSING_DELETE_LOADING, false);
    });
};

export const attachDocumentsInProcessing = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(SET_ATTACH_DOCUMENTS_IN_PROCESSING_LOADING, true);
  store.commit(SET_ATTACH_DOCUMENTS_IN_PROCESSING_SUCCESS, null);
  store.commit(SET_ATTACH_DOCUMENTS_IN_PROCESSING_ERROR, null);
  return await axios
    .post(options.resource, options.data)
    .then(() => {
      store.commit(SET_ATTACH_DOCUMENTS_IN_PROCESSING_SUCCESS, true);
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.save_success", {
            item: null,
          })
        )
      );
    })
    .catch((error: AxiosError) => {
      store.commit(SET_ATTACH_DOCUMENTS_IN_PROCESSING_ERROR, error);
    })
    .finally(() => {
      store.commit(SET_ATTACH_DOCUMENTS_IN_PROCESSING_LOADING, false);
    });
};

export const sendRouteInfo = async (store: any, screenName: any): Promise<void> => {
  store.commit(SET_SUCCESS, null);
  return await axios
    .post(`driving-schools/report/${screenName}`)
    .then(() => {
      store.commit(SET_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_ERROR, error);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  uploadDocument,
  documentsInProcessing,
  documentsInProcessingDelete,
  attachDocumentsInProcessing,
  sendRouteInfo,
};
