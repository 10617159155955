import i18n from "@/i18n";
import { MODULE_COMPANY } from "@/constants/Permissions";

export default [
  {
    path: "/branches",
    name: "Branches",
    meta: {
      title: String(i18n.tc("general.branch", 2)),
      trans: "sidebar.branches",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "branches" */ "@/views/Branch/Branches.vue"),
  },
];
