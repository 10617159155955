import axios from "@/utils/axios";
import { ActionTree } from "vuex";
import { State } from "./state";
import { SET_REGIONS, SET_CONCEPTS, SET_LICENSE_CLASSES, SET_DATA_ITEM, SET_DATA_LIST, SET_SUCCESS, SET_ERROR, IS_LOADING } from "./types";
import { Vue } from "vue-property-decorator";
import i18n from "@/i18n";

export const findAbibaroDataItem = ({ commit }: any, options: any): Promise<any> => {
  const { resource, id } = options;
  const url = resource + id ? +"/" + id : "";
  commit(IS_LOADING, true);
  return axios
    .get(url)
    .then((res: any) => {
      const { data } = res;
      commit(IS_LOADING, false);
      commit(SET_DATA_ITEM, data);
      return data;
    })
    .catch((e: any) => {
      commit(IS_LOADING, false);
      return e;
    });
};

export const findAbibaroDataList = ({ commit }: any, options: any): Promise<any> => {
  const { resource, id, data } = options;
  const url = resource + id ? +"/" + id : "";
  const params = data ? data : null;

  commit(IS_LOADING, true);
  return axios
    .get(url, { params })
    .then((res: any) => {
      const { data } = res;
      commit(IS_LOADING, false);
      commit(SET_DATA_LIST, data);
      return data;
    })
    .catch((e: any) => {
      commit(IS_LOADING, false);
      return e;
    });
};
export const findAbibaroLicenseClasses = ({ commit }: any, options: any): Promise<any> => {
  const { resource, id, data } = options;
  const url = resource + (id ? "/" + id : +"");
  const params = data ? data : null;

  commit(IS_LOADING, true);
  return axios
    .get(url, params)
    .then((res: any) => {
      const { data } = res;
      commit(IS_LOADING, false);
      commit(SET_LICENSE_CLASSES, data);
      return data;
    })
    .catch((e: any) => {
      commit(IS_LOADING, false);
      return e;
    });
};
export const registerAbibaroStudent = ({ commit }: any, options: any): Promise<any> => {
  const { resource, id, data } = options;
  const url = resource + (id ? "/" + id : +"");
  commit(IS_LOADING, true);
  commit(SET_SUCCESS, false);
  commit(SET_ERROR, false);

  return axios
    .post(url, data)
    .then((res: any) => {
      const { data } = res;
      commit(IS_LOADING, false);
      commit(SET_SUCCESS, true);
      Vue.toasted.success(String(i18n.t("messages.operation_successful")));
      return data || true;
    })
    .catch((e: any) => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
      Vue.toasted.error(String(i18n.t("messages.operation_error")));
      return e;
    });
};

export const update = async ({ commit }: any, options: any): Promise<void> => {
  const { resource, id, data } = options;
  const url = resource + id ? +"/" + id : "";
  commit(IS_LOADING, true);
  commit(SET_SUCCESS, false);
  return await axios
    .put(url, data)
    .then(({ data }) => {
      commit(IS_LOADING, false);
      commit(SET_DATA_ITEM, data);
      commit(SET_SUCCESS, true);
      return data;
    })
    .catch(({ response }) => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, response.data);
      return response;
    });
};

export default <ActionTree<State, any>>{
  update,
  findAbibaroDataItem,
  findAbibaroDataList,
  findAbibaroLicenseClasses,
  registerAbibaroStudent,
};
