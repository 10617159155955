import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_LIST, SET_DATA_ITEM_LOADING, SET_TOTAL, SET_DATA_ITEM } from "@/store/Crud/functions/mutations";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ITEM,
};
