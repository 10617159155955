import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { ActionTree } from "vuex";
import { State } from "./state";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";

import {
  IS_LOADING,
  SET_DATA_ITEM,
  SET_ERROR,
  SET_SUCCESS,
  SET_PNG_MIME_TYPE,
  SET_PNG_SUCCESS,
  SET_PNG_ITEM,
  SET_PNG_FILENAME,
  SET_PDF_MIME_TYPE,
} from "@/store/TheoryDocuments/types";

const URL_DELETE = "/theory-lesson-documents/signature";

const URL_SIGNATURE = "/theory-lesson-documents/upload-signature";
const URL_ATTENDANCE = "/theory-lesson-documents";

export const findAttendanceDocument = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL_ATTENDANCE}/${id}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_PDF_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const deleteSignature = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${URL_DELETE}?studentTheoryLessonId=${options.studentTheoryLessonId}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const uploadPngSignature = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_PNG_SUCCESS, null);
  store.commit(SET_ERROR, null);
  return await axios
    .post(`${URL_SIGNATURE}?studentId=${options.studentId}&theoryLessonId=${options.theoryLessonId}`, options.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_PNG_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error);
      store.commit(SET_PNG_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  uploadPngSignature,
  findAttendanceDocument,
  deleteSignature,
};
