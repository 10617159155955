export default class SessionStorageService {
  public static setToLocalStorage(key: string, data: any): void {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  public static getFromLocalStorage(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  public static removeFromLocalStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  public static listenLocalStorage(callback: any): void {
    window.addEventListener("storage", callback, false);
  }
}
