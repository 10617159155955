import i18n from "@/i18n";
import { MODULE_COMPANY } from "@/constants/Permissions";

export default [
  {
    path: "/master-data",
    name: "MasterData",
    meta: {
      title: String(i18n.t("general.base_data")),
      trans: "general.base_data",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "master-data" */ "@/views/Company/MasterData/MasterData.vue"),
  },
];
