export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_PNG_MIME_TYPE = "SET_PNG_MIME_TYPE";
export const SET_PNG_SUCCESS = "SET_PNG_SUCCESS";
export const SET_PNG_ITEM = "SET_PNG_ITEM";
export const SET_PNG_FILENAME = "SET_PNG_FILENAME";
export const SET_PDF_MIME_TYPE = "SET_PDF_MIME_TYPE";
