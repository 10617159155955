import i18n from "@/i18n";

const DaysOfWeek = [
  i18n.t("days.monday"),
  i18n.t("days.tuesday"),
  i18n.t("days.wednesday"),
  i18n.t("days.thursday"),
  i18n.t("days.friday"),
  i18n.t("days.saturday"),
  i18n.t("days.sunday"),
];

export default DaysOfWeek;
