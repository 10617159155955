import { GetterTree } from "vuex";
import { State } from "./state";

export function getMessage(state: State): any {
  return state.message;
}

export function isLoading(state: State): any {
  return state.loading;
}
export default <GetterTree<State, any>>{
  getMessage,
  isLoading,
};
