import { ActionTree } from "vuex";
import { State } from "./state";
import { findAll, create, filter, findOne } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_SUCCESS } from "@/store/Mandate/types";
import axios from "@/utils/axios";

const STUDENTS_URL = "/students";

export const sendMandateDispatchEmail = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${STUDENTS_URL}/send-live-pay-mandate-dispatch-email?email=${options.email}&educationId=${options.educationId}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  findAll,
  create,
  filter,
  findOne,
  sendMandateDispatchEmail,
};
