import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA } from "./types";
import { SET_TOTAL } from "@/store/Todo/types";

export default <MutationTree<State>>{
  [IS_LOADING](state: State, isLoading: boolean) {
    Object.assign(state, { isLoading });
  },

  [SET_ERROR](state: State, error: Record<string, any> | null) {
    Object.assign(state, { error });
  },

  [SET_SUCCESS](state: State, success: boolean) {
    Object.assign(state, { success });
  },

  [SET_DATA](state: State, data: any) {
    Object.assign(state, { data });
  },

  [SET_TOTAL](state: State, total: number) {
    Object.assign(state, { total });
  },
};
