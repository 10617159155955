import i18n from "@/i18n";
import { MODULE_COMPANY } from "@/constants/Permissions";

export default [
  {
    path: "/vehicles",
    name: "Vehicles",
    meta: {
      title: String(i18n.t("sidebar.vehicles")),
      trans: "sidebar.vehicles",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "vehicles" */ "@/views/Vehicle/Vehicles.vue"),
  },
];
