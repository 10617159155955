import { ActionContext } from "vuex";
import CrudService from "@/services/CrudService";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import i18n from "@/i18n";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import { AxiosError, AxiosResponse } from "axios";
import { CrudState } from "@/store/Crud/State";

/**
 * Basic CRUD actions
 */
export default class CrudActions extends MessageDispatcher {
  protected crudService: CrudService = new CrudService();

  constructor() {
    super();
  }

  /**
   * @param store
   * @param options
   */
  public async findOne(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("SET_DATA_ITEM_LOADING", !options.disabledLoading);
    const request: Promise<any> = this.crudService.findOne(options);

    request
      .then((response: any) => {
        this.resolveSingleAction(store, response);
      })
      .catch((error) => {
        this.rejectReadAction(store, String(i18n.t(error.response.data.error_message)));
      });

    return request;
  }

  /**
   * @param store
   * @param options
   */
  public async findAll(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", true);
    return this.crudService
      .findAll(options)
      .then((response: any) => {
        this.resolveReadMultipleAction(store, response);
        return Promise.resolve(response);
      })
      .catch((error) => {
        this.rejectReadAction(store, error);
        return Promise.reject(error);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async filter(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", true);
    return this.crudService
      .filter(options)
      .then((response: any) => {
        this.resolveReadMultipleAction(store, response);
      })
      .catch((error) => {
        this.rejectReadAction(store, error);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async create(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", !options.disabledLoading);
    store.commit("SET_SUCCESS", false);
    store.commit("SET_DATA_ID", null);

    return this.crudService
      .create(options)
      .then((response: any) => {
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.t("messages.save_success", {
              item: options.descriptionField ? response.data[options.descriptionField] : "",
            })
          ),
          response.data,
          options
        );
        store.commit("SET_DATA_ID", response.data);
        store.commit("SET_SUCCESS", true);
      })
      .catch((error) => {
        this.rejectWriteAction(store, error);
        store.commit("SET_DATA_ID", null);
        store.commit("SET_SUCCESS", false);
      });
  }

  /**
   * @param store
   * @param options
   */
  public async update(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", true);
    store.commit("SET_SUCCESS", false);
    store.commit("SET_DATA_ID", null);
    store.commit("SET_ERROR", null);
    const request: Promise<any> = this.crudService.update(options);
    request
      .then((response: any) => {
        const itemLabel: string = options.descriptionField || "";
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.t("messages.save_success", {
              item: itemLabel,
            })
          ),
          response.data,
          options
        );
        store.commit("SET_DATA_ID", response.data ? response.data : -1);
        store.commit("SET_SUCCESS", true);
      })
      .catch((error) => {
        this.rejectWriteAction(store, error);
        store.commit("SET_SUCCESS", false);
        store.commit("SET_DATA_ID", null);
      });

    return request;
  }

  /**
   * @param store
   * @param options
   */
  public async delete(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    store.commit("IS_LOADING", true);
    store.commit("SET_DATA_DELETED", false);
    return this.crudService
      .delete(options)
      .then((response: any) => {
        const resourceName = options.resource;
        const itemLabel: string = options.descriptionField ? options.descriptionField : String(i18n.t("general." + resourceName));
        this.resolveWriteAction(
          store,
          response,
          String(
            i18n.t("messages.delete_success", {
              resourceName: itemLabel,
            })
          ),
          null,
          options
        );
        store.commit("SET_DATA_DELETED", true);
      })
      .catch((error) => {
        const { message, error_message } = error.response.data;
        this.rejectWriteAction(store, String(i18n.t(message ? message : error_message)));
        store.commit("SET_DATA_DELETED", false);
      });
  }

  public resolveWriteAction(store: ActionContext<any, any>, response: any, successMassage: string, item: any, options: ICrudOptions): void {
    if (!options.hideSuccessMessage) {
      this.dispatchSuccessMessage(store, successMassage);
    }
    store.commit("SET_ERROR", null);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on write action promise reject
   * @param store
   * @param error
   */
  public rejectWriteAction(store: ActionContext<any, any>, error: any): void {
    store.commit("SET_ERROR", error);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on read multiple action promise resolve
   * @param store
   * @param response
   */
  public resolveReadMultipleAction(store: ActionContext<any, any>, response: any): void {
    if (response.data.data && response.data.data instanceof Array) {
      return this.resolveReadFilterAction(store, response);
    } else {
      return this.resolveReadFindAllAction(store, response);
    }
  }

  /**
   * Actions to perform on read multiple action promise resolve
   * @param store
   * @param response
   */
  protected resolveReadFilterAction(store: ActionContext<any, any>, response: any): void {
    store.commit("SET_DATA_LIST", response.data.data);
    store.commit("SET_TOTAL", response.data.total);
    store.commit("SET_ERROR", null);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on read multiple action promise resolve
   * @param store
   * @param response
   */
  protected resolveReadFindAllAction(store: ActionContext<any, any>, response: any): void {
    store.commit("SET_DATA_LIST", response.data);
    store.commit("SET_TOTAL", response.data.total);
    store.commit("SET_ERROR", null);
    store.commit("IS_LOADING", false);
  }

  /**
   * Actions to perform on read single action promise resolve
   * @param store
   * @param response
   */
  protected resolveSingleAction(store: ActionContext<any, any>, response: any): void {
    store.commit("SET_DATA_ITEM", response.data);
    store.commit("SET_ERROR", null);
    store.commit("SET_DATA_ITEM_LOADING", false);
  }

  /**
   * Actions to perform on rad action promise reject
   * @param store
   * @param error
   */
  public rejectReadAction(store: ActionContext<any, any>, error: any) {
    store.commit("SET_ERROR", error);
    store.commit("SET_DATA_ITEM_LOADING", false);
  }

  /**
   * Action to perform upload file
   *
   * @param store
   * @param options
   */
  public async uploadFile(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<void> {
    store.commit("SET_FILE_SUCCESS", false);
    store.commit("SET_FILE_ERROR", null);
    store.commit("SET_FILE_LOADING", true);
    return this.crudService
      .uploadFile(options)
      .then((response: AxiosResponse) => {
        store.commit("SET_FILE_SUCCESS", true);
        store.commit("SET_FILE_RESPONSE", response.data);
      })
      .catch((error: AxiosError) => {
        store.commit("SET_FILE_ERROR", error.response?.data);
      })
      .finally(() => {
        store.commit("SET_FILE_LOADING", false);
      });
  }
}
