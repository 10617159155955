import { GetterTree } from "vuex";
import { ExamState } from "@/store/Exam/State";

export const getBookingSuccess = (state: ExamState) => state.bookingSuccess;
export const isBookingLoading = (state: ExamState) => state.bookingLoading;
export const getUploadSuccess = (state: ExamState) => state.uploadSuccess;
export const isUploadLoading = (state: ExamState) => state.uploadLoading;
export const getExamStatusSuccess = (state: ExamState) => state.examStatusSuccess;
export const isExamStatusLoading = (state: ExamState) => state.examStatusLoading;
export const getParticipantsListSuccess = (state: ExamState) => state.participantsListSuccess;
export const isParticipantsListLoading = (state: ExamState) => state.participantsListLoading;

export const getPortionError = (state: ExamState) => state.portionError;

export const getPortionSuccess = (state: ExamState) => state.portionSuccess;

export const getPortionIsLoading = (state: ExamState) => state.portionIsLoading;

export const getPortionDataList = (state: ExamState) => state.portionDataList;

export const getReadySuccess = (state: ExamState) => state.readySuccess;
export const getReadyIsLoading = (state: ExamState) => state.readyIsLoading;
export const getReadyError = (state: ExamState) => state.readyError;
export const getRemoveParticipantSuccess = (state: ExamState) => state.removeParticipantSuccess;
export const getEducationStatusInfoData = (state: ExamState) => state.educationStatusInfoData;

export default <GetterTree<ExamState, any>>{
  getBookingSuccess,
  isBookingLoading,
  getUploadSuccess,
  isUploadLoading,
  getExamStatusSuccess,
  isExamStatusLoading,
  getParticipantsListSuccess,
  isParticipantsListLoading,
  getPortionError,
  getPortionSuccess,
  getPortionIsLoading,
  getPortionDataList,
  getReadySuccess,
  getReadyIsLoading,
  getReadyError,
  getRemoveParticipantSuccess,
  getEducationStatusInfoData,
};
