import { MutationTree } from "vuex";
import { State } from "./state";

export function SET_CONTENT(state: State, content: any) {
  state.content = content;
}

export function SET_STATUS(state: State, status: boolean) {
  state.status = status;
}

export default <MutationTree<State>>{
  SET_CONTENT,
  SET_STATUS,
};
