export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_EDUCATIONS_LIST = "SET_EDUCATIONS_LIST";
export const SET_SALDO_LIST = "SET_SALDO_LIST";
export const SET_SALDO_LIST_LOADING = "SET_SALDO_LIST_LOADING";
export const SET_MIME_TYPE = "SET_MIME_TYPE";

export const SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE = "SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE";
export const SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_ERROR = "SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_ERROR";
export const SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_SUCCESS = "SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_SUCCESS";
export const SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_LOADING = "SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_LOADING";
