








import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const CommunicationModule = namespace("communication");

@Component
export default class CommunicationBadge extends Vue {
  public name = "CommunicationBadge";

  @CommunicationModule.Getter("getCommunicationBadge")
  public communicationBadge!: number;
}
