import { ArchiveState } from "@/store/Crud/functions/archived/State";

export class State extends ArchiveState {
  isBookAtStudentRegistrationAllowed = false;

  learningMaterialProducts = [];
  learningMaterialProductsSuccess = false;
  learningMaterialProductsError: Record<any, any> | null = null;
  learningMaterialProductsLoading = false;

  basicFeeProducts = [];
  basicFeeProductsSuccess = false;
  basicFeeProductsError: Record<any, any> | null = null;
  basicFeeProductsLoading = false;
}
