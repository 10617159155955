import { ActionTree } from "vuex";
import { State } from "./state";
import { create, findOne, update, findAll, filter } from "@/store/Crud/functions/actions";

const URL = "/role-types";

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
};
