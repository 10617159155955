import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_LIST, SET_DATA_ITEM_LOADING, SET_TOTAL, SET_DATA_ID } from "@/store/Crud/functions/mutations";
import { ILivePayMandate } from "@/interfaces/ILivePayMandate";

export function SET_MANDATE_REVOKE_LOADING(state: State, loading: boolean) {
  state.mandateRevokeLoading = loading;
}
export function SET_MANDATE_REVOKE_ERROR(state: State, error: Record<any, any> | null) {
  state.mandateRevokeError = error;
}
export function SET_MANDATE_REVOKE_SUCCESS(state: State, success: boolean) {
  state.mandateRevokeSuccess = success;
}
export function SET_MANDATE_REVOKE_RESPONSE(state: State, mandate: ILivePayMandate | null) {
  state.mandateRevokeResponse = mandate;
}

export function SET_MANDATE_BY_STUDENT_EDUCATION_LOADING(state: State, loading: boolean) {
  state.mandateByStudentEducationLoading = loading;
}
export function SET_MANDATE_BY_STUDENT_EDUCATION_ERROR(state: State, error: Record<any, any> | null) {
  state.mandateByStudentEducationError = error;
}
export function SET_MANDATE_BY_STUDENT_EDUCATION_SUCCESS(state: State, success: boolean) {
  state.mandateByStudentEducationSuccess = success;
}
export function SET_MANDATE_BY_STUDENT_EDUCATION_RESPONSE(state: State, mandate: ILivePayMandate | null) {
  state.mandateByStudentEducationResponse = mandate;
}
export function SET_MANDATE_BY_STUDENT_LOADING(state: State, loading: boolean) {
  state.mandateByStudentLoading = loading;
}
export function SET_MANDATE_BY_STUDENT_ERROR(state: State, error: Record<any, any> | null) {
  state.mandateByStudentError = error;
}
export function SET_MANDATE_BY_STUDENT_SUCCESS(state: State, success: boolean) {
  state.mandateByStudentSuccess = success;
}
export function SET_MANDATE_BY_STUDENT_RESPONSE(state: State, mandate: Array<ILivePayMandate>) {
  state.mandateByStudentResponse = mandate;
}
export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  SET_MANDATE_REVOKE_LOADING,
  SET_MANDATE_REVOKE_ERROR,
  SET_MANDATE_REVOKE_SUCCESS,
  SET_MANDATE_REVOKE_RESPONSE,
  SET_MANDATE_BY_STUDENT_EDUCATION_LOADING,
  SET_MANDATE_BY_STUDENT_EDUCATION_ERROR,
  SET_MANDATE_BY_STUDENT_EDUCATION_SUCCESS,
  SET_MANDATE_BY_STUDENT_EDUCATION_RESPONSE,
  SET_MANDATE_BY_STUDENT_LOADING,
  SET_MANDATE_BY_STUDENT_ERROR,
  SET_MANDATE_BY_STUDENT_SUCCESS,
  SET_MANDATE_BY_STUDENT_RESPONSE,
};
