import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_DATA_ITEM, SET_SUCCESS, SET_MIME_TYPE, SET_DATA_LIST } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, findOne, update, filter, findAll } from "@/store/Crud/functions/actions";
import { Vue } from "vue-property-decorator";
import i18n from "@/i18n";
// @ts-ignore

const URL = "/student-documents";

export const findStudentDocument = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL}/${id}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const findStudentDocumentWithLetterHead = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL}/${options.id}?removeLetterhead=${options.removeLetterhead}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response);
      store.commit(SET_SUCCESS, false);
      Vue.toasted.error("Das Entfernen des Briefkopfes ist für diesen Dokumententyp nicht erlaubt!");
    });
};

export const updateDocumentVisible = async (store: any, options: any): Promise<void> => {
  store.commit(SET_SUCCESS, false);
  store.commit(IS_LOADING, true);
  return await axios
    .put(`student-documents/visible/${options.id}?visible=${options.documentVisible}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, false);
      store.commit(SET_ERROR, error);
    });
};

export const generateInvoicePreview = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "post",
    url: `/payment-documents/create-final-invoice-preview?studentEducationId=${id}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const updateInvoiceVisible = async (store: any, options: any): Promise<void> => {
  store.commit(SET_SUCCESS, false);
  store.commit(IS_LOADING, true);
  return await axios
    .put(`invoices/visible/${options.id}?visible=${options.documentVisible}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, false);
      store.commit(SET_ERROR, error);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  findAll,
  update,
  findStudentDocument,
  findStudentDocumentWithLetterHead,
  updateDocumentVisible,
  generateInvoicePreview,
  updateInvoiceVisible,
};
