import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getMailboxData = (state: State) => state.mailboxData;
export const getTotalItems = (state: State) => state.totalItems;
export const getEmailFilter = (state: State) => state.emailFilter;
export const getEmailFilterNoMarked = (state: State) => state.emailFilter.filter((f) => f.id !== "marked");
export const getEmailFilterIds = (state: State) =>
  state.emailFilter.map((f) => {
    if (f.id !== "marked") return f.id;
  });
export const getMailboxFilter = (state: State) => state.mailboxFilter;
export const getSelectedTargetEmails = (state: State) => state.selectedTargetEmails;
export const getSelectedEmailContent = (state: State) => state.selectedEmailContent;
export const getEmailTemplates = (state: State) => state.emailTemplates;
export const getEmailTemplatesByCategory = (state: State) => state.emailTemplatesByCategory;
export const getSelectedEmailTemplate = (state: State) => state.selectedEmailTemplate;
export const getEmailTemplateCategories = (state: State) => state.emailTemplateCategories;
export const getPlaceholders = (state: State) => state.placeholders;

export const getForceSyncByEmailConfigIdsLoading = (state: State) => state.forceSyncByEmailConfigIdsLoading;
export const getForceSyncByEmailConfigIdsSuccess = (state: State) => state.forceSyncByEmailConfigIdsSuccess;
export const getForceSyncByEmailConfigIdsError = (state: State) => state.forceSyncByEmailConfigIdsError;
export const getStudent = (state: State) => state.student;
export const getBlob = (state: State) => state.blob;
export const getMimeType = (state: State) => state.mimeType;
export const getCostBearer = (state: State) => state.costBearer;
export const getAttachExamDocument = (state: State) => state.attachExamDocument;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotalItems,
  getId,
  getMailboxData,
  getMailboxFilter,
  getEmailFilter,
  getSelectedTargetEmails,
  getSelectedEmailContent,
  getEmailTemplates,
  getEmailTemplatesByCategory,
  getEmailFilterIds,
  getEmailFilterNoMarked,
  getForceSyncByEmailConfigIdsLoading,
  getForceSyncByEmailConfigIdsSuccess,
  getForceSyncByEmailConfigIdsError,
  getSelectedEmailTemplate,
  getEmailTemplateCategories,
  getPlaceholders,
  getStudent,
  getBlob,
  getMimeType,
  getCostBearer,
  getAttachExamDocument,
};
