import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import { AxiosResponse } from "axios";
import { SET_USER_IMAGE, IS_LOADING } from "./types";

const getUserImageAction = ({ commit }: any, options: any) => {
  const { url } = options;
  commit(IS_LOADING, true);
  return axios({
    url,
    method: "GET",
    responseType: "blob",
  })
    .then(({ data }: AxiosResponse) => {
      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        commit(SET_USER_IMAGE, url);
        commit(IS_LOADING, false);
        return url;
      }
    })
    .catch(() => {
      commit(IS_LOADING, false);
    });
};
export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
  getUserImageAction,
};
