




import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const CommunicationModule = namespace("communication");

@Component
export default class RegistrationBadge extends Vue {
  public name = "RegistrationBadge";

  @CommunicationModule.Getter("getRegistrationBadge")
  public registrationBadge!: number;
}
