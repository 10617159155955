import { ActionTree } from "vuex";
import { State } from "./state";
import i18n from "@/i18n";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
// @ts-ignore
import queryString from "query-string";
import { dataItems, dataTemplates, placeholders } from "./data";

import { IS_LOADING, SET_SUCCESS, SET_ERROR, SET_TEMPLATES_BY_CATEGORY, SET_TEMPLATES, SET_TEMPLATE_CATEGORIES } from "./types";
import { IEmailTemplate } from "@/interfaces/IEmailTemplate.ts";

const getTemplatesAction = ({ commit, state }: any, param: any): Promise<void> => {
  const url = state.resource + "/" + param;

  commit(SET_SUCCESS, false);
  commit(IS_LOADING, true);

  return axios
    .get(url)
    .then((res) => {
      const { data } = res.data;
      if (data) {
        commit(IS_LOADING, false);
        commit(SET_SUCCESS, true);
        commit(SET_TEMPLATES_BY_CATEGORY, data);
      }
    })
    .catch(() => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
    });
};
const getTemplatesAllAction = ({ state, commit }: any): Promise<void> => {
  const url = state.resource;

  commit(SET_SUCCESS, false);
  commit(IS_LOADING, true);

  return axios
    .get(url)
    .then((res) => {
      const { data } = res;
      if (data) {
        commit(IS_LOADING, false);
        commit(SET_TEMPLATES, data);
      }
    })
    .catch(() => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
    });
};

const removeSelectedTemplateAction = ({ state, commit, dispatch }: any, id: any): Promise<any> => {
  const url = state.resource + "/" + id;

  commit("IS_LOADING", true);
  commit("SET_ERROR", false);

  return axios
    .delete(url)
    .then((res) => {
      commit("IS_LOADING", false);
      dispatch("showMessage", {
        type: "success",
        placeholder: "sidebar.email_template",
        text: "sidebar.delete_success",
      });
      return res;
    })
    .catch(({ response }) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return response;
    });
};
const getTemplateCategoriesAction = ({ state, commit }: any, options?: any): Promise<any> => {
  const urlGet = state.resource + "-categories";

  commit("IS_LOADING", true);
  commit("SET_ERROR", false);

  return axios
    .get(urlGet)
    .then((res: any) => {
      const { data } = res;
      commit("SET_TEMPLATE_CATEGORIES", []);
      commit("SET_TEMPLATE_CATEGORIES", data);
      commit("IS_LOADING", false);
      return res;
    })
    .catch((e: any) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return e;
    });
};

const createTemplateCategoryAction = ({ state, commit, dispatch }: any, params: any): Promise<any> => {
  const url = state.resource + "-categories";

  commit(IS_LOADING, true);
  commit(SET_ERROR, false);

  return axios
    .post(url, { name: params })
    .then((res) => {
      const { status } = res;
      commit(IS_LOADING, false);
      commit(SET_ERROR, false);
      return res;
    })
    .catch((e: any) => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
      return e;
    });
};

const createTemplateByCategoryAction = ({ state, commit, dispatch }: any, params: IEmailTemplate): Promise<void> => {
  const url = state.resource;

  commit("IS_LOADING", true);
  commit("SET_ERROR", false);

  return axios
    .post(url, params)
    .then((res) => {
      commit("IS_LOADING", false);
      dispatch("showMessage", {
        type: "success",
        placeholder: "sidebar.email_template",
        text: "sidebar.save_success",
      });
      return res;
    })
    .catch((err: any) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return err;
    });
};

const editTemplateByCategoryAction = ({ state, commit, dispatch }: any, params: IEmailTemplate): Promise<any> => {
  const { id } = params;
  const url = state.resource + "/" + id;

  commit("IS_LOADING", true);
  commit("SET_ERROR", false);

  return axios
    .put(url, params)
    .then((res) => {
      commit("IS_LOADING", false);
      dispatch("showMessage", {
        type: "success",
        placeholder: "sidebar.email_template",
        text: "sidebar.save_success",
      });
      return res;
    })
    .catch(({ response }) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return response;
    });
};
const getPlaceholdersAction = ({ state, commit }: any): Promise<any> => {
  const url = state.resource + "/placeholders";

  commit("IS_LOADING", true);
  commit("SET_ERROR", false);

  return axios
    .get(url)
    .then((res) => {
      const { data } = res;
      commit("IS_LOADING", false);
      commit("SET_PLACEHOLDERS", data);
      return res;
    })
    .catch((e: any) => {
      commit("SET_ERROR", true);
      commit("IS_LOADING", false);
      return e;
    });
};
export const removeCategoryAction = async ({ state, commit, dispatch }: any, id: any): Promise<any> => {
  const url = state.resource + "-categories/" + id;

  commit("IS_LOADING", true);
  commit("SET_ERROR", false);

  return axios
    .delete(url)
    .then((res) => {
      commit("IS_LOADING", false);
      commit("SET_SUCCESS", true);
      dispatch("showMessage", {
        type: "success",
        placeholder: "sidebar.email_template_category",
        text: "sidebar.delete_success",
      });
      return res;
    })
    .catch(({ response }) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return response;
    });
};
const showMessage = ({ dispatch }: any, payload: any) => {
  const { text, placeholder, type } = payload;
  dispatch(
    "snackbar/setMessage",
    {
      text: String(i18n.t(text, { placeholder: String(i18n.t(placeholder)) })),
      type,
    },
    { root: true }
  );
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getTemplateCategoriesAction,
  removeSelectedTemplateAction,
  createTemplateCategoryAction,
  createTemplateByCategoryAction,
  getPlaceholdersAction,
  getTemplatesAllAction,
  removeCategoryAction,
  showMessage,
  getTemplatesAction,
  editTemplateByCategoryAction,
};
