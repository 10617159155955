import { ActionTree } from "vuex";
import { State } from "./state";
import { create, findOne, update, findAll } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import {
  IS_LOADING,
  SET_DATA_LIST,
  SET_ERROR,

  // dashboard
  SET_ERROR_DASHBOARD_USER_CALENDAR,
  SET_DATA_LIST_DASHBOARD_USER_CALENDAR,
  LOADING_DASHBOARD_USER_CALENDAR,

  // check instructor working hours
  SET_DATA_CHECK_WORKING_HOURS,
  LOADING_CHECK_WORKING_HOURS,
  SET_SUCCESS_CHECK_WORKING_HOURS,
  SET_ERROR_CHECK_WORKING_HOURS,

  // check working hours repeating event
  SET_DATA_CHECK_WORKING_HOURS_REPEATING_EVENT,
  LOADING_CHECK_WORKING_HOURS_REPEATING_EVENT,
  SET_SUCCESS_CHECK_WORKING_HOURS_REPEATING_EVENT,
  SET_ERROR_CHECK_WORKING_HOURS_REPEATING_EVENT,

  // check instructor overlapping appointments
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS,

  // check instructor overlapping appointments repeating event
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT,

  // check vehicle overlapping appointments
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE,

  // check vehicle overlapping appointments repeating event
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT,

  // check vehicle as array overlapping appointments
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY,
} from "@/store/Calendar/types";
import { ICheckOverlappingAppointmentsOptions } from "@/interfaces/ICheckOverlappingAppointmentsOptions";
import { ICheckOverlappingAppointmentsOptionsVehicle } from "@/interfaces/ICheckOverlappingAppointmentsOptionsVehicle";
import { ICheckWorkingHoursRepeatingEvent } from "@/interfaces/ICheckOverlappingAppointmentsRepeatingEventOptions";
import { ICheckOverlappingAppointmentsRepeatingEventOptionsVehicle } from "@/interfaces/ICheckOverlappingAppointmentsRepeatingEventOptionsVehicle";

export const filter = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post("/calendar/filter", filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};
export const filterDashboardCalendar = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(LOADING_DASHBOARD_USER_CALENDAR, true);
  return await axios
    .post("/calendar/filter", filter)
    .then(({ data }) => {
      store.commit(LOADING_DASHBOARD_USER_CALENDAR, false);
      store.commit(SET_DATA_LIST_DASHBOARD_USER_CALENDAR, data);
    })
    .catch(({ response }) => {
      store.commit(LOADING_DASHBOARD_USER_CALENDAR, false);
      store.commit(SET_ERROR_DASHBOARD_USER_CALENDAR, response.data);
    });
};

/**
 * Check instructor working hours
 */
export const checkInstructorWorkingHours = async (store: any, options: ICheckOverlappingAppointmentsOptions): Promise<void> => {
  store.commit(SET_DATA_CHECK_WORKING_HOURS, null);
  store.commit(LOADING_CHECK_WORKING_HOURS, true);
  store.commit(SET_SUCCESS_CHECK_WORKING_HOURS, false);
  store.commit(SET_ERROR_CHECK_WORKING_HOURS, null);
  return await axios
    .post(`/calendar/instructor/${options.instructorId}/check-working-hours`, options.data)
    .then(({ data }) => {
      store.commit(SET_DATA_CHECK_WORKING_HOURS, data);
      store.commit(SET_SUCCESS_CHECK_WORKING_HOURS, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR_CHECK_WORKING_HOURS, response.data);
    })
    .finally(() => {
      store.commit(LOADING_CHECK_WORKING_HOURS, false);
    });
};
export const checkInstructorWorkingHoursRepeatingEvent = async (store: any, options: ICheckWorkingHoursRepeatingEvent): Promise<void> => {
  store.commit(SET_DATA_CHECK_WORKING_HOURS_REPEATING_EVENT, null);
  store.commit(LOADING_CHECK_WORKING_HOURS_REPEATING_EVENT, true);
  store.commit(SET_SUCCESS_CHECK_WORKING_HOURS_REPEATING_EVENT, false);
  store.commit(SET_ERROR_CHECK_WORKING_HOURS_REPEATING_EVENT, null);
  return await axios
    .post(`/calendar/instructor/${options.instructorId}/repeating-event/check-working-hours`, options.data)
    .then(({ data }) => {
      store.commit(SET_DATA_CHECK_WORKING_HOURS_REPEATING_EVENT, data);
      store.commit(SET_SUCCESS_CHECK_WORKING_HOURS_REPEATING_EVENT, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR_CHECK_WORKING_HOURS_REPEATING_EVENT, response.data);
    })
    .finally(() => {
      store.commit(LOADING_CHECK_WORKING_HOURS_REPEATING_EVENT, false);
    });
};

/**
 * Check instructor overlapping appointments
 */
export const checkInstructorOverlappingAppointments = async (store: any, options: ICheckOverlappingAppointmentsOptions): Promise<void> => {
  store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS, null);
  store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS, true);
  store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS, false);
  store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS, null);
  return await axios
    .post(`/calendar/instructor/${options.instructorId}/check-overlapping-appointments`, options.data)
    .then(({ data }) => {
      store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS, data);
      store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS, response.data);
    })
    .finally(() => {
      store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS, false);
    });
};
export const checkInstructorOverlappingAppointmentsRepeatingEvent = async (store: any, options: ICheckWorkingHoursRepeatingEvent): Promise<void> => {
  store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT, null);
  store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT, true);
  store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT, false);
  store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT, null);
  return await axios
    .post(`/calendar/instructor/${options.instructorId}/repeating-event/check-overlapping-appointments`, options.data)
    .then(({ data }) => {
      store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT, data);
      store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT, response.data);
    })
    .finally(() => {
      store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT, false);
    });
};

/**
 * Check vehicle overlapping appointments
 */
export const checkVehicleOverlappingAppointments = async (store: any, options: ICheckOverlappingAppointmentsOptionsVehicle): Promise<void> => {
  store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE, null);
  store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE, true);
  store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE, false);
  store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE, null);
  return await axios
    .post(`/calendar/vehicle/${options.vehicleId}/check-overlapping-appointments`, options.data)
    .then(({ data }) => {
      store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE, data);
      store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE, response.data);
    })
    .finally(() => {
      store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE, false);
    });
};
export const checkVehicleOverlappingAppointmentsRepeatingEvent = async (
  store: any,
  options: ICheckOverlappingAppointmentsRepeatingEventOptionsVehicle
): Promise<void> => {
  store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT, null);
  store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT, true);
  store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT, false);
  store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT, null);
  return await axios
    .post(`/calendar/vehicle/${options.vehicleId}/repeating-event/check-overlapping-appointments`, options.data)
    .then(({ data }) => {
      store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT, data);
      store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT, response.data);
    })
    .finally(() => {
      store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT, false);
    });
};
export const checkVehicleOverlappingAppointmentsAsArray = async (store: any, options: ICheckOverlappingAppointmentsOptionsVehicle): Promise<void> => {
  store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY, null);
  store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY, true);
  store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY, false);
  store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY, null);

  return await axios
    .post(`/calendar/vehicle/check-overlapping-appointments`, options.data)
    .then(({ data }) => {
      store.commit(SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY, data);
      store.commit(SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY, response.data);
    })
    .finally(() => {
      store.commit(LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  checkInstructorWorkingHours,
  checkInstructorWorkingHoursRepeatingEvent,
  checkInstructorOverlappingAppointments,
  checkInstructorOverlappingAppointmentsRepeatingEvent,
  checkVehicleOverlappingAppointments,
  checkVehicleOverlappingAppointmentsRepeatingEvent,
  checkVehicleOverlappingAppointmentsAsArray,
  filterDashboardCalendar,
};
