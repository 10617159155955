import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { ActionTree } from "vuex";
import { State } from "./state";
import axios from "@/utils/axios";
import { SET_SUCCESS, IS_LOADING, SET_ERROR } from "@/store/Pulse/types";

export const pulseMessageIsRead = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, null);
  return await axios
    .post(`${URL}/book-addons?studentId=${data.id}`, data.data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  findAll,
  findOne,
  update,
  create,
  filter,
  pulseMessageIsRead,
};
