import { CrudState } from "@/store/Crud/State";
export class State extends CrudState {
  finishedEducationStateSuccess = false;
  finishedEducationStateError = false;
  finishedEducationStateIsLoading = false;

  automaticPaymentStateSuccess = false;
  automaticPaymentStateError = false;
  automaticPaymentStateIsLoading = false;

  questionVersion = null;
  driveBuzzEnabled = null;

  dataItemCostBearer: Record<string, any> = {};
}
