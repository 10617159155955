import { State } from "./state";
import { MutationTree } from "vuex";

import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
  SET_DATA_DELETED,
} from "@/store/Crud/functions/mutations";

import { SET_EMAIL_EXISTS, SET_BLOB, SET_MIME_TYPE } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_DATA_ITEM_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_DELETED,

  [SET_EMAIL_EXISTS](state: State, emailExists: boolean) {
    Object.assign(state, { emailExists });
  },
  [SET_BLOB](state: State, blob: any) {
    Object.assign(state, { blob });
  },
  [SET_MIME_TYPE](state: State, mimeType: string) {
    Object.assign(state, { mimeType });
  },
};
