import { ActionContext } from "vuex";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import i18n from "@/i18n";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import ArchiveService from "@/services/ArchiveService";

/**
 *Basic CRUD actions
 */
export default class ArchiveActions extends MessageDispatcher {
  protected archiveService: ArchiveService = new ArchiveService();

  /**
   *
   * @param store
   * @param options
   */
  public async archive(store: ActionContext<any, any>, options: ICrudOptions): Promise<any> {
    await this.dispatchLoading(store, !options.disabledLoading);
    store.commit("IS_LOADING", !options.disabledLoading);
    store.commit("SET_ARCHIVE_SUCCESS", false);

    return this.archiveService
      .archive(options)
      .then((response: any) => {
        if (options.params.archived != null && options.params.archived === false) {
          this.dispatchSuccessMessage(store, String(i18n.t("messages.unarchive_success")));
        } else {
          this.dispatchSuccessMessage(store, String(i18n.t("messages.archive_success")));
        }
        this.dispatchLoading(store, false);
        store.commit("SET_ERROR", null);
        store.commit("IS_LOADING", false);
        store.commit("SET_ARCHIVE_SUCCESS", true);
      })
      .catch((error) => {
        this.dispatchLoading(store, false);
        store.commit("SET_ERROR", error);
        store.commit("IS_LOADING", false);
        store.commit("SET_ARCHIVE_SUCCESS", false);
      });
  }
}
