import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_SUCCESS } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import i18n from "@/i18n";

const URL = "/practical-exams";

export const booked = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}/${id}/book`)
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      store.dispatch(
        "snackbar/setMessage",
        {
          text: String(
            i18n.t("messages.save_success", {
              resourceName: String(i18n.t("calendar.practical_exam")),
            })
          ),
          type: "success",
        },
        { root: true }
      );
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, false);
      store.commit(SET_ERROR, error.response?.data);
      store.dispatch(
        "snackbar/setMessage",
        {
          text: String(i18n.t(error.response?.data.error_message)),
          type: "error",
        },
        { root: true }
      );
    });
};

export const deletePracticalExam = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${URL}/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  booked,
  deletePracticalExam,
};
