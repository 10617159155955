export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_DATA_WORK_HOURS = "SET_DATA_WORK_HOURS";

export const SET_STUDENT_QUESTION_ANSWER_METRICS = "SET_STUDENT_QUESTION_ANSWER_METRICS";
export const SET_STUDENT_QUESTION_ANSWER_METRICS_SUCCESS = "SET_STUDENT_QUESTION_ANSWER_METRICS_SUCCESS";
export const SET_STUDENT_QUESTION_ANSWER_METRICS_ERROR = "SET_STUDENT_QUESTION_ANSWER_METRICS_ERROR";
export const SET_STUDENT_QUESTION_ANSWER_METRICS_LOADING = "SET_STUDENT_QUESTION_ANSWER_METRICS_LOADING";

export const SET_STUDENT_QUESTION_ANSWER_STATS = "SET_STUDENT_QUESTION_ANSWER_STATS";
export const SET_STUDENT_QUESTION_ANSWER_STATS_SUCCESS = "SET_STUDENT_QUESTION_ANSWER_STATS_SUCCESS";
export const SET_STUDENT_QUESTION_ANSWER_STATS_ERROR = "SET_STUDENT_QUESTION_ANSWER_STATS_ERROR";
export const SET_STUDENT_QUESTION_ANSWER_STATS_LOADING = "SET_STUDENT_QUESTION_ANSWER_STATS_LOADING";
