export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";

export const LOADING_CHECK_WORKING_HOURS = "LOADING_CHECK_WORKING_HOURS";
export const SET_DATA_CHECK_WORKING_HOURS = "SET_DATA_CHECK_WORKING_HOURS";
export const SET_ERROR_CHECK_WORKING_HOURS = "SET_ERROR_CHECK_WORKING_HOURS";
export const SET_SUCCESS_CHECK_WORKING_HOURS = "SET_SUCCESS_CHECK_WORKING_HOURS";

export const LOADING_CHECK_OVERLAPPING_APPOINTMENTS = "LOADING_CHECK_OVERLAPPING_APPOINTMENTS";
export const SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS = "SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS";
export const SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS = "SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS";
export const SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS = "SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS";

export const LOADING_CHECK_WORKING_HOURS_REPEATING_EVENT = "LOADING_CHECK_WORKING_HOURS_REPEATING_EVENT";
export const SET_DATA_CHECK_WORKING_HOURS_REPEATING_EVENT = "SET_DATA_CHECK_WORKING_HOURS_REPEATING_EVENT";
export const SET_ERROR_CHECK_WORKING_HOURS_REPEATING_EVENT = "SET_ERROR_CHECK_WORKING_HOURS_REPEATING_EVENT";
export const SET_SUCCESS_CHECK_WORKING_HOURS_REPEATING_EVENT = "SET_SUCCESS_CHECK_WORKING_HOURS_REPEATING_EVENT";

export const LOADING_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT = "LOADING_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT";
export const SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT = "SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT";
export const SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT = "SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT";
export const SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT = "SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT";

export const LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE = "LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE";
export const SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE = "SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE";
export const SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE = "SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE";
export const SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE = "SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE";

export const LOADING_DASHBOARD_USER_CALENDAR = "LOADING_DASHBOARD_USER_CALENDAR";
export const SET_DATA_LIST_DASHBOARD_USER_CALENDAR = "SET_DATA_LIST_DASHBOARD_USER_CALENDAR";
export const SET_ERROR_DASHBOARD_USER_CALENDAR = "SET_ERROR_DASHBOARD_USER_CALENDAR";

export const SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT = "SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT";
export const LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT = "LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT";
export const SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT = "SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT";
export const SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT = "SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT";

export const SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY = "SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY";
export const LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY = "LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY";
export const SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY = "SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY";
export const SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY = "SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY";
