import { Module } from "vuex";
import { State } from "./state";
import Mutations from "./mutations";
import Getters from "./getters";
export default class LocalFilter implements Module<State, any> {
  namespaced = true;
  state: State;
  mutations = Mutations;
  getters = Getters;

  constructor() {
    this.state = new State();
  }
}
