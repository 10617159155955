import i18n from "@/i18n";

export default [
  {
    path: "/pulse",
    name: "Pulse",
    meta: {
      title: String(i18n.t("sidebar.pulse")),
      trans: "sidebar.pulse",
    },
    component: () => import(/* webpackChunkName: "pulse" */ "@/views/Pulse/Pulse.vue"),
  },
];
