import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";
import { getArchiveSuccess } from "@/store/Crud/functions/archived/getters";

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getArchiveSuccess,
};
