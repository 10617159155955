import i18n from "@/i18n";
import { MODULE_TIME_PLANNER } from "@/constants/Permissions";

export default [
  {
    path: "/scheduler",
    name: "Scheduler",
    meta: {
      title: String(i18n.t("sidebar.scheduler")),
      trans: "sidebar.scheduler",

      permissions: [MODULE_TIME_PLANNER],
    },
    component: () => import(/* webpackChunkName: "scheduler" */ "@/views/Scheduler/Scheduler.vue"),
  },
];
