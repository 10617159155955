import { CrudState } from "@/store/Crud/State";

export function SET_DATA_LIST(state: CrudState, data: any) {
  state.dataList = data;
}

export function SET_TOTAL(state: CrudState, total: any) {
  state.total = total;
}

export function SET_DATA_ITEM(state: CrudState, item: any) {
  state.dataItem = item;
}

export function SET_ERROR(state: CrudState, error: any) {
  state.error = error;
}

export function IS_LOADING(state: CrudState, isLoading: any) {
  state.isLoading = isLoading;
}

export function SET_SUCCESS(state: CrudState, success: any) {
  state.success = success;
}

export function SET_DATA_ID(state: CrudState, id: number) {
  state.id = id;
}

export function SET_DATA_DELETED(state: CrudState, isDeleted: any) {
  state.deleted = isDeleted;
}

export function SET_FILE_SUCCESS(state: CrudState, fileSuccess: boolean) {
  state.fileSuccess = fileSuccess;
}

export function SET_FILE_ERROR(state: CrudState, fileError: Record<any, any> | null) {
  state.fileError = fileError;
}

export function SET_FILE_LOADING(state: CrudState, fileLoading: boolean) {
  state.fileLoading = fileLoading;
}

export function SET_FILE_RESPONSE(state: CrudState, fileResponse: any) {
  state.fileResponse = fileResponse;
}

export function SET_DATA_ITEM_LOADING(state: CrudState, dataItemLoading: boolean) {
  state.dataItemLoading = dataItemLoading;
}

export const SET_SELECTED_ROW_NAME = (state: CrudState, selectedRowName: string) => {
  state.selectedRowName = selectedRowName;
};
