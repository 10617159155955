import axios from "@/utils/axios";
import { IS_LOADING, SET_COURSE_DATA_LIST, SET_DATA_LIST, SET_ERROR, SET_SUCCESS, SET_MATCH_DATA_ITEM, SET_BOOKED } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, findAll, findOne, update, del } from "@/store/Crud/functions/actions";
// @ts-ignore
import queryString from "query-string";
import { Vue } from "vue-property-decorator";
import i18n from "@/i18n";

const URL = "/theory-lesson";

export const filter = async (store: any, filter: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL}/filter`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findByCourseId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/course/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_COURSE_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findMatchedById = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/${options.theoryLessonId}/check-student-match/${options.studentId}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_MATCH_DATA_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const remove = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${URL}/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const book = async ({ commit }: any, id: any): Promise<any> => {
  if (!id) return Promise.reject(new Error("Theory lesson ID is not provided"));
  const bookingTheoryLessonUrl = `/theory-lesson/${id}/book`;
  commit(IS_LOADING, true);
  commit(SET_SUCCESS, false);
  return await axios
    .post(bookingTheoryLessonUrl)
    .then((response: any) => {
      commit(IS_LOADING, false);
      commit(SET_SUCCESS, true);
      commit(SET_BOOKED, true);
      Vue.toasted.success(String(i18n.t("messages.course_booked")));
    })
    .catch((er: any) => {
      commit(IS_LOADING, false);
      commit(SET_SUCCESS, false);
      commit(SET_BOOKED, false);
      Vue.toasted.error(String(i18n.t("messages.course_not_booked")));
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findByCourseId,
  remove,
  findAll,
  del,
  findMatchedById,
  book,
};
