export const MODULE_COMMUNICATION = "MODULE_COMMUNICATION";
export const MODULE_TASK = "MODULE_TASK";
export const MODULE_CUSTOMERS = "MODULE_CUSTOMERS";
export const MODULE_COST_BEARERS = "MODULE_COST_BEARERS";
export const MODULE_TIME_PLANNER = "MODULE_TIME_PLANNER";
export const MODULE_THEORY = "MODULE_THEORY";
export const MODULE_EXAM = "MODULE_EXAM";
export const MODULE_REPORTS = "MODULE_REPORTS";
export const MODULE_AUTOMATIC_PAYMENTS = "MODULE_AUTOMATIC_PAYMENTS";
export const MODULE_ACCOUNTING = "MODULE_ACCOUNTING";
export const MODULE_COMPANY = "MODULE_COMPANY";

// Write
export const MODULE_COMMUNICATION_WRITE = "MODULE_COMMUNICATION_WRITE";
export const MODULE_TASK_WRITE = "MODULE_TASK_WRITE";
export const MODULE_COST_BEARERS_WRITE = "MODULE_COST_BEARERS_WRITE";
export const MODULE_CUSTOMERS_WRITE = "MODULE_CUSTOMERS_WRITE";
export const MODULE_TIME_PLANNER_WRITE = "MODULE_TIME_PLANNER_WRITE";
export const MODULE_THEORY_WRITE = "MODULE_THEORY_WRITE";
export const MODULE_EXAM_WRITE = "MODULE_EXAM_WRITE";
export const MODULE_REPORTS_WRITE = "MODULE_REPORTS_WRITE";
export const MODULE_AUTOMATIC_PAYMENTS_WRITE = "MODULE_AUTOMATIC_PAYMENTS_WRITE";
export const MODULE_ACCOUNTING_WRITE = "MODULE_ACCOUNTING_WRITE";
export const MODULE_COMPANY_WRITE = "MODULE_COMPANY_WRITE";
// TODO: change with backend permission name
export const MODULE_PAYMENT_WRITE = "MODULE_PAYMENT_WRITE";
