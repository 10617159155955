import { Module, ModuleTree } from "vuex";
import { State } from "./state";
import Mutations from "./mutations";
import Getters from "./getters";
import Actions from "./actions";
import CalendarReadable from "@/store/User/CalendarReadable";
import CalendarWriteable from "@/store/User/CalendarWriteable";

export default class User implements Module<State, any> {
  namespaced = true;
  state: State;
  mutations = Mutations;
  getters = Getters;
  actions = Actions;
  modules: ModuleTree<any>;

  constructor() {
    this.state = new State();
    this.modules = {
      "calendar-readable": new CalendarReadable(),
      "calendar-writeable": new CalendarWriteable(),
    };
  }
}
