import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import { SET_PNG_MIME_TYPE, SET_PNG_SUCCESS, SET_PNG_ITEM, SET_PNG_FILENAME, SET_PDF_MIME_TYPE } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_PNG_MIME_TYPE](state: State, pngMimeType: string) {
    Object.assign(state, { pngMimeType });
  },

  [SET_PNG_SUCCESS](state: State, pngSuccess: any) {
    Object.assign(state, { pngSuccess });
  },

  [SET_PNG_ITEM](state: State, pngItem: any) {
    Object.assign(state, { pngItem });
  },

  [SET_PNG_FILENAME](state: State, pngFileName: any) {
    Object.assign(state, { pngFileName });
  },

  [SET_PDF_MIME_TYPE](state: State, pdfMimeType: string) {
    Object.assign(state, { pdfMimeType });
  },
};
