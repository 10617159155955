import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { findAll, filter } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_LIST, SET_TOTAL } from "../types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { cancelInvoice } from "@/store/PaymentWorkflow/actions";

export const generateFinalInvoices = async (store: ActionContext<any, any>, ids: Array<number>): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`/payment-documents/create-final-invoices`, ids)
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error.response?.data);
    });
};

export const fetchPaymentDocumentsFiltered = async ({ commit }: ActionContext<any, any>, options: any) => {
  commit(IS_LOADING, true);
  commit(SET_SUCCESS, false);

  return axios
    .post(`${options.resource}`, options.filter)
    .then((response: any) => {
      if (response && response.data) {
        commit(SET_DATA_LIST, response.data.data);
        commit(SET_TOTAL, response.data.total);
        commit(IS_LOADING, false);
        commit(SET_SUCCESS, true);
      }
    })
    .catch((error: AxiosError) => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, error.response?.data);
    });
};
export default <ActionTree<State, any>>{
  findAll,
  generateFinalInvoices,
  cancelInvoice,
  filter,
  fetchPaymentDocumentsFiltered,
};
