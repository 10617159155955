import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { findAll, findOne } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_SUCCESS } from "../types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";

export const generatePartialInvoices = async (store: ActionContext<any, any>, ids: Array<number>): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`/payment-documents/flash-pay/create-partial-invoices`, ids)
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error.response?.data);
    });
};

export default <ActionTree<State, any>>{
  findAll,
  generatePartialInvoices,
  findOne,
};
