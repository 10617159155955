import { State } from "./state";
import { GetterTree } from "vuex";
import { getDataList, getError, getSuccess, getIsLoading, getId, getTotal } from "@/store/Crud/functions/getters";
import { getCancelInvoiceSuccess, getCancelInvoiceError, getCancelInvoice, getCancelInvoiceLoading } from "@/store/PaymentWorkflow/getters";

export default <GetterTree<State, any>>{
  getDataList,
  getError,
  getSuccess,
  getIsLoading,
  getId,
  getTotal,
  getCancelInvoiceSuccess,
  getCancelInvoiceError,
  getCancelInvoice,
  getCancelInvoiceLoading,
};
