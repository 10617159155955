import { PluginObject } from "vue";
import Router from "vue-router";
import UserService from "@/services/UserService";
import {
  MODULE_COMMUNICATION,
  MODULE_TASK,
  MODULE_CUSTOMERS,
  MODULE_COST_BEARERS,
  MODULE_TIME_PLANNER,
  MODULE_THEORY,
  MODULE_EXAM,
  MODULE_REPORTS,
  MODULE_AUTOMATIC_PAYMENTS,
  MODULE_ACCOUNTING,
  MODULE_COMPANY,
  MODULE_COMMUNICATION_WRITE,
  MODULE_TASK_WRITE,
  MODULE_COST_BEARERS_WRITE,
  MODULE_TIME_PLANNER_WRITE,
  MODULE_THEORY_WRITE,
  MODULE_EXAM_WRITE,
  MODULE_REPORTS_WRITE,
  MODULE_AUTOMATIC_PAYMENTS_WRITE,
  MODULE_ACCOUNTING_WRITE,
  MODULE_COMPANY_WRITE,
  MODULE_CUSTOMERS_WRITE,
  MODULE_PAYMENT_WRITE,
} from "@/constants/Permissions";

interface IAbilityPlugin {
  permissions: Array<string>;
  router: Router;
}

const AbilityPlugin: PluginObject<IAbilityPlugin> = {
  install(vue, option) {
    vue.prototype.$can = (key: string) => {
      if (key == undefined) return true;
      const permissions = UserService.getPermissions();
      return permissions.includes(key);
    };

    if (option?.router) {
      option.router.beforeEach((to, from, next) => {
        if (to.meta && to.meta.permissions && to.meta.permissions.length > 0) {
          const permissions = UserService.getPermissions();
          //const isAllowed = permissions.some((p: string) => to.meta?.permissions.includes(p));
          const isAllowed = to.meta?.permissions.every((p: string) => permissions.includes(p));
          if (!isAllowed) return option.router.push({ path: from.path }).catch(() => null);
        }
        next();
      });
    }

    vue.mixin({
      computed: {
        MODULE_COMMUNICATION: function () {
          return MODULE_COMMUNICATION;
        },
        MODULE_TASK: function () {
          return MODULE_TASK;
        },
        MODULE_CUSTOMERS: function () {
          return MODULE_CUSTOMERS;
        },
        MODULE_COST_BEARERS: function () {
          return MODULE_COST_BEARERS;
        },
        MODULE_TIME_PLANNER: function () {
          return MODULE_TIME_PLANNER;
        },
        MODULE_THEORY: function () {
          return MODULE_THEORY;
        },
        MODULE_EXAM: function () {
          return MODULE_EXAM;
        },
        MODULE_REPORTS: function () {
          return MODULE_REPORTS;
        },
        MODULE_AUTOMATIC_PAYMENTS: function () {
          return MODULE_AUTOMATIC_PAYMENTS;
        },
        MODULE_ACCOUNTING: function () {
          return MODULE_ACCOUNTING;
        },
        MODULE_COMPANY: function () {
          return MODULE_COMPANY;
        },

        MODULE_COMMUNICATION_WRITE: function () {
          return MODULE_COMMUNICATION_WRITE;
        },
        MODULE_TASK_WRITE: function () {
          return MODULE_TASK_WRITE;
        },
        MODULE_COST_BEARERS_WRITE: function () {
          return MODULE_COST_BEARERS_WRITE;
        },
        MODULE_CUSTOMERS_WRITE: function () {
          return MODULE_CUSTOMERS_WRITE;
        },
        MODULE_TIME_PLANNER_WRITE: function () {
          return MODULE_TIME_PLANNER_WRITE;
        },
        MODULE_THEORY_WRITE: function () {
          return MODULE_THEORY_WRITE;
        },
        MODULE_EXAM_WRITE: function () {
          return MODULE_EXAM_WRITE;
        },
        MODULE_REPORTS_WRITE: function () {
          return MODULE_REPORTS_WRITE;
        },
        MODULE_AUTOMATIC_PAYMENTS_WRITE: function () {
          return MODULE_AUTOMATIC_PAYMENTS_WRITE;
        },
        MODULE_ACCOUNTING_WRITE: function () {
          return MODULE_ACCOUNTING_WRITE;
        },
        MODULE_COMPANY_WRITE: function () {
          return MODULE_COMPANY_WRITE;
        },
        MODULE_PAYMENT_WRITE: function () {
          return MODULE_PAYMENT_WRITE;
        },
      },
    });
  },
};

export default AbilityPlugin;
