import { State } from "./state";
import { MutationTree } from "vuex";

import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
  SET_DATA_DELETED,
} from "@/store/Crud/functions/mutations";

import { SET_TEST_ITEM } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_DATA_ITEM_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_DELETED,

  [SET_TEST_ITEM](state: State, testItem: Record<any, any>) {
    Object.assign(state, { testItem });
  },
};
