import { State } from "./state";
import { GetterTree } from "vuex";
import {
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getDeleted,
  getDataItemLoading
} from "@/store/Crud/functions/getters";

export const getCoursesAndTheoryLessons = (state: State) => state.coursesAndTheoryLessons;

export const getUpcomingTheoryCourses = (state: State) => state.upcomingTheoryCourses;

export const getFilterStudentTheoryCourses = (state: State) => state.filterStudentTheoryCourses;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getCoursesAndTheoryLessons,
  getDeleted,
  getUpcomingTheoryCourses,
  getFilterStudentTheoryCourses,
  getDataItemLoading
};
