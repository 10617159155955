import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_DATA_LIST, SET_SUCCESS } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, findOne, update, filter, findAll } from "@/store/Crud/functions/actions";

const URL = "/student-note";

export const findAllByStudent = async (store: any, id: number): Promise<void> => {
  store.commit(SET_SUCCESS, null);
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/student/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  findAll,
  update,
  findAllByStudent,
};
