import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import CrudService from "@/services/CrudService";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IS_LOADING, SET_DATA, SET_TOTAL, SET_ERROR, SET_SUCCESS } from "./types";
import { AxiosError, AxiosResponse } from "axios";

const crudService: CrudService = new CrudService();

const filter = async (store: ActionContext<State, any>, options: ICrudOptions) => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  store.commit(SET_ERROR, null);
  return await crudService
    .filter(options, "")
    .then((response: AxiosResponse) => {
      if (response.data.data && response.data.data instanceof Array) {
        store.commit(SET_DATA, response.data.data);
        store.commit(SET_TOTAL, response.data.total);
      } else {
        store.commit(SET_DATA, response.data);
      }
      store.commit(SET_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};

export default <ActionTree<State, any>>{
  filter,
};
