import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import { SET_BALANCE_OVERVIEW } from "./types";
import { IBalanceOverview } from "@/interfaces/IBalanceOverview";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_BALANCE_OVERVIEW](state: State, balanceOverview: IBalanceOverview) {
    Object.assign(state, { balanceOverview });
  },
};
