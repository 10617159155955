import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll, del } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_SUCCESS } from "./types";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import i18n from "@/i18n";

const URL = "prices";

const messageDispatcher = new MessageDispatcher();

export const findAllByPriceList = async (store: any, id: number): Promise<void> => {
  store.commit("IS_LOADING", true);
  return await axios
    .get(`${URL}/by-pricelist/${id}`)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_DATA_LIST", data);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
    });
};

export const combination = async (store: any, data: any): Promise<void> => {
  store.commit("IS_LOADING", true);
  store.commit("SET_SUCCESS", false);
  return await axios
    .post(`${URL}/combinations`, data)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_DATA_LIST", data);
      store.commit("SET_SUCCESS", true);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
    });
};

export const findAllByStudent = async (store: any, id: number): Promise<void> => {
  store.commit("IS_LOADING", true);
  return await axios
    .get(`${URL}/by-student/${id}`)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_DATA_LIST", data);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
    });
};

export const findAllAddonsByStudent = async (store: any, id: number): Promise<void> => {
  store.commit("IS_LOADING", true);
  return await axios
    .get(`${URL}/addons-by-student/${id}`)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ADDON_DATA_LIST", data);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
    });
};

export const findAllByStudentEducation = async (store: any, id: number): Promise<void> => {
  store.commit("IS_LOADING", true);
  return await axios
    .get(`${URL}/by-student-education/${id}`)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_DATA_LIST", data);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
    });
};

export const copyToExistStudent = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  store.commit(SET_ERROR, null);
  return await axios
    .post(`${URL}/copy-to-existing-students`, id, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(() => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.save_success", {
            item: null,
          })
        )
      );
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  del,
  findAll,
  findAllByPriceList,
  combination,
  findAllByStudent,
  findAllAddonsByStudent,
  findAllByStudentEducation,
  copyToExistStudent,
};
