import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId, getDeleted, getDataItemLoading } from "@/store/Crud/functions/getters";

export const getCourseDataList = (state: State) => state.courseDataList;
export const getMatchDataItem = (state: State) => state.matchDataItem;
export const getBooked = (state: State) => state.booked;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getCourseDataList,
  getId,
  getDeleted,
  getMatchDataItem,
  getDataItemLoading,
  getBooked,
};
