export class CrudState {
  error: Record<string, any> | null = null;
  success = false;
  isLoading = false;
  dataList: Array<any> = [];
  dataItem: Record<string, any> = {};
  total = 0;
  id = 0;
  deleted = false;
  fileError: Record<any, any> | null = null;
  fileSuccess = false;
  fileLoading = false;
  fileResponse: any = null;
  dataItemLoading = false;

  selectedRowName = "";
}
