import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_SUCCESS } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import queryString from "query-string";
import { findAll, create, findOne, update, filter } from "@/store/Crud/functions/actions";

const URL = "/broadcasts";

export const archive = async (store: any, data: any): Promise<void> => {
  const queryBuilder = queryString.stringify(data);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}/archive?${queryBuilder}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      // store.commit(SET_DATA_ITEM, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  archive,
  findAll,
};
