import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_DATA_ID,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM_LOADING,
  SET_TOTAL,
  SET_DATA_DELETED,
} from "@/store/Crud/functions/mutations";

import { SET_ARCHIVE_SUCCESS } from "@/store/Crud/functions/archived/mutations";

import {
  SET_BOOKING_SUCCESS,
  IS_BOOKING_LOADING,
  SET_UPLOAD_SUCCESS,
  IS_UPLOAD_LOADING,
  SET_EXAM_STATUS_SUCCESS,
  IS_EXAM_STATUS_LOADING,
  SET_PARTICIPANT_LIST_SUCCESS,
  IS_PARTICIPANT_LIST_LOADING,
  SET_PORTION_DATA_LIST,
  SET_PORTION_ERROR,
  SET_PORTION_LOADING,
  SET_PORTION_SUCCESS,
  SET_READY_SUCCESS,
  SET_READY_ERROR,
  SET_READY_LOADING,
  SET_REMOVE_PARTICIPANT_SUCCESS,
  SET_EDUCATION_STATUS_INFO,
} from "@/store/Exam/mutations";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_ARCHIVE_SUCCESS,
  SET_BOOKING_SUCCESS,
  IS_BOOKING_LOADING,
  SET_UPLOAD_SUCCESS,
  IS_UPLOAD_LOADING,
  SET_EXAM_STATUS_SUCCESS,
  IS_EXAM_STATUS_LOADING,
  SET_PARTICIPANT_LIST_SUCCESS,
  IS_PARTICIPANT_LIST_LOADING,
  SET_PORTION_DATA_LIST,
  SET_PORTION_ERROR,
  SET_PORTION_LOADING,
  SET_PORTION_SUCCESS,
  SET_READY_SUCCESS,
  SET_READY_ERROR,
  SET_READY_LOADING,
  SET_REMOVE_PARTICIPANT_SUCCESS,
  SET_DATA_ITEM_LOADING,
  SET_EDUCATION_STATUS_INFO,
  SET_DATA_DELETED,
};
