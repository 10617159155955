import { ActionTree } from "vuex";
import { State } from "./state";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_KEY_NUMBERS_LIST, SET_DATA_LIST } from "./types";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";

const URL = "/license-classes";

export const findKeysByLicenseClass = async (store: any, license: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`${URL}/key-numbers?baseLicenseClass=${license}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_KEY_NUMBERS_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findByDrivingSchoolId = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`${URL}/base-license-classes?`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_KEY_NUMBERS_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const relatedDocumentsByLicenseClass = async (store: any, licenseClass: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`${URL}/related-documents?licenseClass=${licenseClass}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  findKeysByLicenseClass,
  relatedDocumentsByLicenseClass,
  findByDrivingSchoolId,
};
