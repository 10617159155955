import { Module, ModuleTree } from "vuex";
import Item from "@/store/Abstract/Item";

export default class Gocardless implements Module<any, any> {
  namespaced = true;
  modules: ModuleTree<any>;

  constructor() {
    this.modules = {
      drivingSchoolEnabled: new Item<boolean>(),
      gocardlessLink: new Item<string>(),
    };
  }
}
