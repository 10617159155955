import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  SET_DATA_DELETED,
} from "@/store/Crud/functions/mutations";
import { SET_COURSE_DATA_LIST, SET_MATCH_DATA_ITEM, SET_BOOKED } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_DELETED,
  SET_DATA_ITEM_LOADING,

  [SET_COURSE_DATA_LIST](state: State, courseDataList: Record<string, any>) {
    Object.assign(state, { courseDataList });
  },
  [SET_MATCH_DATA_ITEM](state: State, matchDataItem: Record<string, any>) {
    Object.assign(state, { matchDataItem });
  },
  [SET_BOOKED](state: State, booked: Record<string, any>) {
    Object.assign(state, { booked });
  },
};
