import axios from "@/utils/axios";
import { IS_LOADING, SET_DATA_WORK_HOURS, SET_ERROR, SET_SUCCESS } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import type { IDayWorkHour } from "@/interfaces/IDayWorkHour";
import { create, filter, findOne, update } from "@/store/Crud/functions/actions";
import { archive } from "@/store/Crud/functions/archived/actions";

const USER_URL = "users";

export const workHours = async (store: any, instructorId: number): Promise<void> => {
  return await axios
    .get(`/instructor-work-hours/${instructorId}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_WORK_HOURS, data.dayWorkHours);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const workHoursUpdate = async (store: any, data: IDayWorkHour): Promise<void> => {
  return await axios
    .put(`/instructor-work-hours/${data.id}`, data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      //store.commit(SET_DATA_WORK_HOURS, data.dayWorkHours);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const userAvatarUpload = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${USER_URL}/avatar`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  workHours,
  workHoursUpdate,
  userAvatarUpload,
  archive,
};
