import { ArchiveState } from "@/store/Crud/functions/archived/State";

export class State extends ArchiveState {
  educationsByCostBearer: Array<any> = [];
  saldoByCostBearer: Array<any> = [];
  saldoByCostBearerLoading = false;
  mimeType = "";

  generateCostBearerClassicPayCustomBookingInvoice = null;
  generateCostBearerClassicPayCustomBookingInvoiceError = null;
  generateCostBearerClassicPayCustomBookingInvoiceSuccess = false;
  generateCostBearerClassicPayCustomBookingInvoiceLoading = false;
}
