import { CrudState } from "@/store/Crud/State";

export class State extends CrudState {
  exams: Array<any> = [];
  avatarIsLoading = false;
  avatarSuccess = null;
  avatarError = null;
  studentsByCourse: Array<any> = [];
  examsCostBearer: Array<any> = [];
  isLoadingCostBearer = false;
  dataItemCostBearer: Record<string, any> = {};
  sendPasswordChangeSuccess = false;
  sendPasswordChangeLoading = false;
  sendPasswordChangeError: Record<any, any> | null = null;
  studentEmails: Array<any> = [];
  studentEmailsError: Record<any, any> | null = null;
  studentEmailsLoading = false;
  studentsByList: Array<any> = [];
  selectedStudent: any = null;
}
