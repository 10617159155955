import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import { SET_PRICELIST_COPY } from "@/store/PriceList/types";
import { SET_ARCHIVE_SUCCESS } from "@/store/Crud/functions/archived/mutations";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_ARCHIVE_SUCCESS,
  SET_DATA_ITEM_LOADING,

  [SET_PRICELIST_COPY](state: State, priceListCopy: "") {
    Object.assign(state, { priceListCopy });
  },
};
