import { ActionTree } from "vuex";
import { State } from "./state";
import i18n from "@/i18n";
import { Vue } from "vue-property-decorator";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import UserService from "@/services/UserService";
import axios from "@/utils/axios";
import { downloadFile } from "@/utils/File";
// @ts-ignore
import queryString from "query-string";

import {
  IS_LOADING,
  SET_SUCCESS,
  SET_ERROR,
  SET_MAILBOX_DATA,
  SET_SWITCH_MAIL_STATUS,
  SET_UPDATE_MAILBOX_LIST,
  SET_TOTAL_ITEMS,
  SET_EMAIL_TEMPLATES,
  SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING,
  SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_SUCCESS,
  SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_ERROR,
  SET_EMAIL_TEMPLATES_BY_CATEGORY,
  SET_STUDENT,
  SET_BLOB,
  SET_MIME_TYPE,
  SET_COST_BEARER,
  SET_ATTACH_EXAM_DOCUMENT,
} from "./types";
import { AxiosError } from "axios";
import { IEmailTemplate } from "@/interfaces/IEmailTemplate.ts";

const setStudentInfo = (store: any, student: any): void => {
  store.commit(SET_STUDENT, student);
};

const setCostBearerInfo = (store: any, costBearer: any): void => {
  store.commit(SET_COST_BEARER, costBearer);
};

const setBlobFile = (store: any, options: any): void => {
  store.commit(SET_BLOB, options.blob);
  store.commit(SET_MIME_TYPE, options.mimeType);
};

const fetchEmailsFilter = async ({ commit }: any): Promise<any> => {
  const userId = UserService.getUser().id;
  const url = "email-config/user/" + userId + "/email-configs";
  commit(IS_LOADING, true);
  commit(SET_SUCCESS, false);
  commit("SET_EMAIL_FILTER", []);
  return axios
    .get(url)
    .then((res) => {
      const { data } = res;
      if (data && data.length > 0) {
        const emailFilter = data.map((item: any) => ({
          id: item.id,
          email: item.email,
        }));
        commit(IS_LOADING, false);
        commit(SET_SUCCESS, true);
        commit("SET_EMAIL_FILTER", emailFilter);
      }
    })
    .catch((e) => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
    });
};
const fetchMailboxFilter = async ({ commit }: any): Promise<any> => {
  const mailboxFilter = await Promise.all([
    { mailbox: await i18n.t("sidebar.inbox"), id: 1, name: "mailbox" },
    { mailbox: await i18n.t("sidebar.outbox"), id: 2, name: "mailbox" },
  ]);
  commit("SET_MAILBOX_FILTER", mailboxFilter);
};

const filterByMailbox = async ({ commit, getters }: any, params: any) => {
  const url = "emails/filter";
  commit(IS_LOADING, true);
  commit(SET_MAILBOX_DATA, []);
  commit(SET_SUCCESS, false);
  commit(SET_ERROR, null);
  if (params.emailConfigIds.length === 0) {
    params = { ...params, emailConfigIds: getters.getEmailFilterIds };
  }
  return await axios
    .post(url, params)
    .then((res) => {
      const { data, total } = res.data;
      if (data && data.length > 0) {
        commit(SET_MAILBOX_DATA, data);
        commit(SET_TOTAL_ITEMS, total);
        commit(SET_SUCCESS, true);
      }
    })
    .catch((error: AxiosError) => {
      commit(SET_ERROR, error.response?.data);
    })
    .finally(() => {
      commit(IS_LOADING, false);
    });
};
const switchMailStatusAction = async ({ commit }: any, params: any): Promise<any> => {
  const { emailId, action } = params;
  const act = action.split("_");
  let url = act[0] === "read" ? "emails/change-read-state?" : "emails/change-marked-state?";
  const bool = act[1] === "0" ? false : true;
  const readMarkParam = act[0] === "read" ? { read: bool } : { marked: bool };
  params = {
    emailId,
    ...readMarkParam,
  };
  const queryBuilder = queryString.stringify(params);
  url = url + queryBuilder;
  axios
    .post(url)
    .then((res) => {
      const { data, status } = res;
      if (status === 200) {
        commit(IS_LOADING, false);
        commit(SET_SUCCESS, true);
        commit(SET_SWITCH_MAIL_STATUS, { emailId, action });
      }
    })
    .catch(() => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
    });
};
const removeEmail = ({ commit }: any, emailId: any) => {
  const url = "emails/" + emailId;
  const param = emailId;

  axios
    .delete(url)
    .then((res) => {
      const { status } = res;
      if (status === 200) {
        commit(IS_LOADING, false);
        commit(SET_SUCCESS, true);
        commit(SET_UPDATE_MAILBOX_LIST, emailId);
      }
    })
    .catch(() => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
    });
};
const getSelectedEmailContentAction = ({ commit, state }: any, params: any): void => {
  const { emailId } = params;
  const data = state.mailboxData.find((mail: any) => mail.id === emailId);
  commit("SET_SELECTED_EMAIL_CONTENT", data);
};

const getEmailTemplatesAction = ({ commit, state }: any, param: any): void => {
  const url = "email-templates/" + param;
  commit(SET_SUCCESS, false);
  axios
    .get(url)
    .then((res) => {
      const { data } = res.data;
      if (data) {
        commit(IS_LOADING, false);
        commit(SET_SUCCESS, true);
        commit(SET_EMAIL_TEMPLATES_BY_CATEGORY, data);
      }
    })
    .catch(() => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
    });
};
const getEmailTemplatesAllAction = ({ commit }: any): void => {
  const url = "email-templates/";
  commit(SET_SUCCESS, false);
  axios
    .get(url)
    .then((res) => {
      const { data } = res;
      if (data) {
        commit(IS_LOADING, false);
        commit(SET_EMAIL_TEMPLATES, data);
      }
    })
    .catch(() => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
    });
};

const sendEmailAction = async ({ commit }: any, data: any): Promise<void> => {
  // const url = "emails/send-user-email";
  const url = "/emails/send-user-email-with-attachments";
  commit(IS_LOADING, true);
  commit(SET_SUCCESS, false);
  return await axios
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      const { status } = res;
      if (status == 200) {
        commit(IS_LOADING, false);
        commit(SET_SUCCESS, true);
        Vue.toasted.success(String(i18n.t("messages.email_sent")));
      }
    })
    .catch(() => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
      Vue.toasted.error(String(i18n.t("messages.email_not_sent")));
    });
};
const downloadFileAction = async (_: any, params: any): Promise<any> => {
  const { filename, id } = params;
  const url = "email-attachments/read/" + id;
  const config = {
    url,
  };
  await downloadFile(config, filename);
};

export const forceSyncByEmailConfigIds = async (store: any, ids: Array<number>) => {
  store.commit(SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING, true);
  store.commit(SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_SUCCESS, false);
  store.commit(SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_ERROR, null);
  return await axios
    .post("/emails/force-sync-by-email-config-ids", ids)
    .then(() => {
      store.commit(SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_SUCCESS, true);
      store.commit(SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING, false);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_ERROR, error.response?.data);
      store.commit(SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING, false);
    })
    .finally(() => {
      store.commit(SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING, false);
    });
};

const removeSelectedTemplateAction = async ({ state, commit, dispatch }: any, id: any) => {
  commit("IS_LOADING", true);
  const url = "/email-templates/" + id;
  const { status } = await axios
    .delete(url)
    .then((res) => res)
    .catch(({ response }) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return response;
    });
  if (status && status === 200) {
    commit("IS_LOADING", false);
    dispatch("showMessage", {
      type: "success",
      placeholder: "sidebar.email_template",
      text: "sidebar.delete_success",
    });
  }
};
const getEmailTemplateCategoriesAction = async ({ commit }: any) => {
  const urlGet = "email-template-categories/";
  commit("IS_LOADING", true);
  const { status, data } = await axios
    .get(urlGet)
    .then((res: any) => res)
    .catch(() => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
    });
  if (status && status === 200 && data) {
    commit("SET_TEMPLATE_CATEGORIES", []);
    commit("SET_TEMPLATE_CATEGORIES", data);
    commit("IS_LOADING", false);
  }
};
const createTemplateCategoryAction = async ({ commit, dispatch }: any, params: any) => {
  commit(IS_LOADING, true);
  const url = "email-template-categories";
  const success = await axios
    .post(url, { name: params })
    .then((res) => {
      const { status } = res;
      return status;
    })
    .catch(() => {
      commit(IS_LOADING, false);
      commit(SET_ERROR, true);
    });
  if (success && success === 200) {
    commit(IS_LOADING, false);
    dispatch("showMessage", {
      type: "success",
      placeholder: "sidebar.email_template_category",
      text: "sidebar.save_success",
    });
  }
};
const createEmailTemplateByCategoryAction = async ({ commit, dispatch }: any, params: IEmailTemplate) => {
  commit("IS_LOADING", true);
  const url = "email-templates";
  // @ts-ignore
  const { status, data } = await axios
    .post(url, params)
    .then((res) => res)
    .catch(({ response }) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return response;
    });
  if (status && status === 200) {
    commit("IS_LOADING", false);
    dispatch("showMessage", {
      type: "success",
      placeholder: "sidebar.email_template",
      text: "sidebar.save_success",
    });
  }
};

const editEmailTemplateByCategoryAction = async ({ commit, dispatch }: any, params: IEmailTemplate) => {
  commit("IS_LOADING", true);
  const { id } = params;
  const url = "email-templates/" + id;
  // @ts-ignore
  const { status } = await axios
    .put(url, params)
    .then((res) => res)
    .catch(({ response }) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return response;
    });
  if (status && status === 200) {
    commit("IS_LOADING", false);
    dispatch("showMessage", {
      type: "success",
      placeholder: "sidebar.email_template",
      text: "sidebar.save_success",
    });
    return;
  }
};
const getPlaceholdersAction = async ({ commit }: any) => {
  commit("IS_LOADING", true);
  const url = "email-templates/placeholders";
  // @ts-ignore
  const { data } = await axios
    .get(url)
    .then((res) => res)
    .catch(() => {
      commit("SET_ERROR", true);
      commit("IS_LOADING", false);
    });
  if (data) {
    commit("IS_LOADING", false);
    commit("SET_PLACEHOLDERS", data);
  }
};
export const removeCategoryAction = async ({ commit, dispatch }: any, id: any) => {
  commit("IS_LOADING", true);
  const url = "email-template-categories/" + id;
  const { status } = await axios
    .delete(url)
    .then((res) => res)
    .catch(({ response }) => {
      commit("IS_LOADING", false);
      commit("SET_ERROR", true);
      return response;
    });
  if (status === 200) {
    commit("IS_LOADING", false);
    commit("SET_SUCCESS", true);
    dispatch("showMessage", {
      type: "success",
      placeholder: "sidebar.email_template_category",
      text: "sidebar.delete_success",
    });
  }
};
const showMessage = ({ dispatch }: any, payload: any) => {
  const { text, placeholder, type } = payload;
  dispatch(
    "snackbar/setMessage",
    {
      text: String(i18n.t(text, { placeholder: String(i18n.t(placeholder)) })),
      type,
    },
    { root: true }
  );
};

const setAttachExamDocument = (store: any, payload: any): void => {
  store.commit(SET_ATTACH_EXAM_DOCUMENT, payload);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  switchMailStatusAction,
  fetchEmailsFilter,
  fetchMailboxFilter,
  filterByMailbox,
  removeEmail,
  getSelectedEmailContentAction,
  getEmailTemplatesAction,
  sendEmailAction,
  downloadFileAction,
  forceSyncByEmailConfigIds,
  removeSelectedTemplateAction,
  createTemplateCategoryAction,
  getEmailTemplateCategoriesAction,
  createEmailTemplateByCategoryAction,
  editEmailTemplateByCategoryAction,
  getPlaceholdersAction,
  getEmailTemplatesAllAction,
  removeCategoryAction,
  showMessage,
  setStudentInfo,
  setBlobFile,
  setCostBearerInfo,
  setAttachExamDocument,
};
