import i18n from "@/i18n";
import { MODULE_TASK } from "@/constants/Permissions";
export default [
  {
    path: "/todo",
    name: "Todo",
    meta: {
      title: String(i18n.t("sidebar.todo")),
      trans: "sidebar.todo",
      permissions: [MODULE_TASK],
    },
    component: () => import(/* webpackChunkName: "todo" */ "@/views/Todo/Todo.vue"),
  },
];
