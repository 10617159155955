import { PaymentWorkflowState } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_LIST, SET_DATA_ITEM_LOADING, SET_TOTAL } from "@/store/Crud/functions/mutations";

export const SET_STUDENT_LIST_SUCCESS = (state: PaymentWorkflowState, studentListSuccess: boolean) => {
  Object.assign(state, { studentListSuccess });
};
export const SET_STUDENT_LIST_ERROR = (state: PaymentWorkflowState, studentListError: any) => {
  Object.assign(state, { studentListError });
};
export const SET_STUDENT_LIST_LOADING = (state: PaymentWorkflowState, studentListLoading: boolean) => {
  Object.assign(state, { studentListLoading });
};
export const SET_STUDENT_LIST = (state: PaymentWorkflowState, studentList: Array<any>) => {
  Object.assign(state, { studentList });
};
export const SET_OVERVIEW_INFO_ERROR = (state: PaymentWorkflowState, overviewInfoError: boolean) => {
  Object.assign(state, { overviewInfoError });
};
export const SET_OVERVIEW_INFO_SUCCESS = (state: PaymentWorkflowState, overviewInfoSuccess: any) => {
  Object.assign(state, { overviewInfoSuccess });
};
export const SET_OVERVIEW_INFO_LOADING = (state: PaymentWorkflowState, overviewInfoLoading: boolean) => {
  Object.assign(state, { overviewInfoLoading });
};
export const SET_OVERVIEW_INFO = (state: PaymentWorkflowState, overviewInfo: any) => {
  Object.assign(state, { overviewInfo });
};
export const SET_CANCEL_INVOICE_ERROR = (state: PaymentWorkflowState, cancelInvoiceError: Record<any, any> | null) => {
  state.cancelInvoiceError = cancelInvoiceError;
};
export const SET_CANCEL_INVOICE_SUCCESS = (state: PaymentWorkflowState, cancelInvoiceSuccess: boolean) => {
  state.cancelInvoiceSuccess = cancelInvoiceSuccess;
};
export const SET_CANCEL_INVOICE_LOADING = (state: PaymentWorkflowState, cancelInvoiceLoading: boolean) => {
  state.cancelInvoiceLoading = cancelInvoiceLoading;
};
export const SET_CANCEL_INVOICE = (state: PaymentWorkflowState, cancelInvoice: any) => {
  state.cancelInvoice = cancelInvoice;
};

export const SET_COST_BEARER_STUDENT_LIST = (state: PaymentWorkflowState, costBearerStudentList: Array<any>) => {
  state.costBearerStudentList = costBearerStudentList;
};
export const SET_COST_BEARER_STUDENT_LIST_LOADING = (state: PaymentWorkflowState, costBearerStudentListLoading: boolean) => {
  state.costBearerStudentListLoading = costBearerStudentListLoading;
};
export const SET_COST_BEARER_STUDENT_LIST_SUCCESS = (state: PaymentWorkflowState, costBearerStudentListSuccess: boolean) => {
  state.costBearerStudentListSuccess = costBearerStudentListSuccess;
};
export const SET_COST_BEARER_STUDENT_LIST_ERROR = (state: PaymentWorkflowState, costBearerStudentListError: Record<any, any> | null) => {
  state.costBearerStudentListError = costBearerStudentListError;
};
export const SET_STUDENT_TOTAL = (state: PaymentWorkflowState, payload: number) => {
  state.studentListTotal = payload;
};
export default <MutationTree<PaymentWorkflowState>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_STUDENT_LIST_SUCCESS,
  SET_STUDENT_LIST_ERROR,
  SET_STUDENT_LIST_LOADING,
  SET_STUDENT_LIST,
  SET_OVERVIEW_INFO_ERROR,
  SET_OVERVIEW_INFO_SUCCESS,
  SET_OVERVIEW_INFO_LOADING,
  SET_OVERVIEW_INFO,
  SET_DATA_ITEM_LOADING,
  SET_COST_BEARER_STUDENT_LIST,
  SET_COST_BEARER_STUDENT_LIST_LOADING,
  SET_COST_BEARER_STUDENT_LIST_SUCCESS,
  SET_COST_BEARER_STUDENT_LIST_ERROR,
  SET_STUDENT_TOTAL,
};
