import { State } from "./state";
import { GetterTree } from "vuex";

import {
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getFileError,
  getFileSuccess,
  getFileLoading,
  getFileResponse,
  getSelectedRowName,
} from "@/store/Crud/functions/getters";
import { getArchiveSuccess } from "@/store/Crud/functions/archived/getters";

export const getEducationsList = (state: State) => state.educationsByCostBearer;
export const getSaldoList = (state: State) => state.saldoByCostBearer;
export const getSaldoListLoading = (state: State) => state.saldoByCostBearerLoading;
export const getMimeType = (state: State) => state.mimeType;
export const getGenerateCostBearerClassicPayCustomBookingInvoice = (state: State) => state.generateCostBearerClassicPayCustomBookingInvoice;
export const getGenerateCostBearerClassicPayCustomBookingInvoiceError = (state: State) => state.generateCostBearerClassicPayCustomBookingInvoiceError;
export const getGenerateCostBearerClassicPayCustomBookingInvoiceSuccess = (state: State) => state.generateCostBearerClassicPayCustomBookingInvoiceSuccess;
export const getGenerateCostBearerClassicPayCustomBookingInvoiceLoading = (state: State) => state.generateCostBearerClassicPayCustomBookingInvoiceLoading;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getEducationsList,
  getSaldoList,
  getFileError,
  getFileSuccess,
  getFileLoading,
  getFileResponse,
  getArchiveSuccess,
  getSaldoListLoading,
  getMimeType,
  getSelectedRowName,
  getGenerateCostBearerClassicPayCustomBookingInvoice,
  getGenerateCostBearerClassicPayCustomBookingInvoiceError,
  getGenerateCostBearerClassicPayCustomBookingInvoiceSuccess,
  getGenerateCostBearerClassicPayCustomBookingInvoiceLoading,
};
