





































/**
 * @see https://vue-select.org/
 * @see https://popper.js.org/
 */
import { Component, Prop, Vue } from "vue-property-decorator";
//@ts-ignore
import vSelect from "@/pkgs/vue-select/src";
import "@/pkgs/vue-select/src/css/vue-select.css";
import { createPopper } from "@popperjs/core";
import { Placement } from "@popperjs/core/lib/enums";
import i18n from "@/i18n";

@Component({
  components: {
    vSelect,
  },
})
export default class FscMultiselect extends Vue {
  public name = "FscMultiselect";

  protected placement: Placement = "bottom-start";

  @Prop({ required: false })
  public value!: any;

  @Prop({ required: false, default: () => [] })
  public options!: any[];

  @Prop({ required: false, default: () => false })
  public clearable!: boolean;

  @Prop({ required: false, default: () => i18n.t("calendar.option_placeholder") })
  public placeholder!: string;

  @Prop({ required: false, default: () => false })
  public searchable!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public preselectFirst!: boolean;

  public withPopper(dropdownList: HTMLUListElement, component: any, { width }: { width: string }) {
    /**
     * We need to explicitly define the dropdown width since
     * it is usually inherited from the parent with CSS.
     */
    dropdownList.style.width = "auto";
    dropdownList.style.minWidth = width;

    /**
     * Here we position the dropdownList relative to the $refs.toggle Element.
     *
     * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
     * the dropdownList overlap by 1 pixel.
     *
     * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
     * wrapper so that we can set some styles for when the dropdown is placed
     * above.
     */
    const popper = createPopper(component.$refs.toggle, dropdownList, {
      placement: this.placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 2],
          },
        },
        {
          name: "toggleClass",
          enabled: true,
          phase: "write",
          fn({ state }) {
            component.$el.classList.toggle("drop-up", state.placement === "top");
          },
        },
      ],
    });

    /**
     * To prevent memory leaks Popper needs to be destroyed.
     * If you return function, it will be called just before dropdown is removed from DOM.
     */
    return () => popper.destroy();
  }

  public input(value: any): void {
    this.$emit("input", value);
  }

  public optionSelecting(selectedOption: any): void {
    this.$emit("option:selecting", selectedOption);
  }

  public optionSelected(selectedOption: any): void {
    this.$emit("option:selected", selectedOption);
    this.$emit("select", selectedOption); //For backward compatibility
  }

  public optionDeselecting(deselectedOption: any): void {
    this.$emit("option:deselecting", deselectedOption);
  }

  public optionDeselected(deselectedOption: any): void {
    this.$emit("option:deselected", deselectedOption);
  }

  public optionCreated(newOption: any): void {
    this.$emit("option:created", newOption);
  }

  public search(newSearchString: string, toggleLoading: boolean): void {
    this.$emit("search", newSearchString, toggleLoading);
  }

  public mounted(): void {
    if (this.preselectFirst && this.options.length && !this.value) {
      // @ts-ignore
      (this.$refs.fscMultiselect as vSelect).updateValue(this.options[0]);
    }
  }
}
