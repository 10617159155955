import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { SET_DATA_LIST, SET_ERROR, IS_LOADING, SET_SELECTED_STUDENT, SET_DATA_ITEM, SET_PROCESS_STATUSES } from "./types";
import axios from "@/utils/axios";
import { SET_SUCCESS } from "@/store/TheoryLesson/types";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import i18n from "@/i18n";
import { AxiosError } from "axios";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";

const URL = "/registrations";
const STUDENTS_URL = "/students/delete-uncomfirmed";
const messageDispatcher = new MessageDispatcher();

export const findRegistrationsById = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}`)
    .then(({ data }) => {
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR, response.data);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};

export const studentMatchingRegistration = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/${id}/student/matching-registration`)
    .then(({ data }) => {
      store.commit(SET_DATA_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR, response.data);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};

export const setSelectedStudent = async (store: any, data: any): Promise<void> => {
  store.commit(SET_SELECTED_STUDENT, data);
};

export const deleteSelectedStudent = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${URL}/${id}`)
    .then(({ data }) => {
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};

export const deleteUnconfirmed = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${STUDENTS_URL}/${id}`)
    .then(({ data }) => {
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};
export const fetchProcessStatuses = ({ commit }: any, options: any) => {
  commit(IS_LOADING, true);
  return axios
    .get("registrations/registration-status-options")
    .then((res: any) => {
      const { data } = res;
      commit(SET_PROCESS_STATUSES, data);

      return res;
    })
    .catch((e: any) => {
      return e;
    })
    .finally(() => {
      commit(IS_LOADING, false);
    });
};
export const updateProcessStatus = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .put(options.resource, options.data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(() => {
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.save_success", {
            item: null,
          })
        )
      );
    })
    .catch((error: AxiosError) => {
      store.commit("SET_ERROR", error);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  findRegistrationsById,
  setSelectedStudent,
  studentMatchingRegistration,
  deleteSelectedStudent,
  deleteUnconfirmed,
  fetchProcessStatuses,
  updateProcessStatus,
};
