import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, del, findAll } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_DATA_LIST, SET_SUCCESS, SET_EMAIL_EXISTS } from "./types";
import axios from "@/utils/axios";
import { AxiosError } from "axios";
import { SET_BLOB, SET_MIME_TYPE } from "@/store/Mailbox/types";
import { Vue } from "vue-property-decorator";
import i18n from "@/i18n";

const URL = "/emails/send-user-email";
const URL_MAIN = "/emails";
const URL_EMAIL_EXISTS = "/students/email-check";

const setBlobFile = (store: any, options: any): void => {
  store.commit(SET_BLOB, options.blob);
  store.commit(SET_MIME_TYPE, options.mimeType);
};

export const isEmailExisting = async (store: any, email: string): Promise<void> => {
  return await axios
    .get(`${URL_EMAIL_EXISTS}/${email}`)
    .then(({ data }) => {
      console.log(data);
    })
    .catch(({ response }) => {
      if (response.status === 302) {
        store.commit(SET_EMAIL_EXISTS, true);
      } else if (response.status === 404) {
        store.commit(SET_EMAIL_EXISTS, false);
      }
    });
};

export const sendUserEmail = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}`, data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const sendEmailWithAttachments = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  store.commit(SET_ERROR, null);
  return await axios
    .post(`${URL_MAIN}/send-user-email-with-attachments`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      Vue.toasted.success(String(i18n.t("messages.email_sent")));
      return data;
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error);
      store.commit(SET_SUCCESS, false);
      Vue.toasted.error(String(i18n.t("messages.email_not_sent")));
      return error;
    });
};

export const sendConfirmationEmail = async (store: any, email: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .get(`students/send-confirmation-email?email=${email}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  del,
  findAll,
  sendUserEmail,
  sendEmailWithAttachments,
  isEmailExisting,
  setBlobFile,
  sendConfirmationEmail,
};
