import { ArchiveState } from "@/store/Crud/functions/archived/State";

export class ExamState extends ArchiveState {
  bookingSuccess = false;
  bookingLoading = false;
  uploadSuccess = false;
  uploadLoading = false;
  examStatusSuccess = false;
  examStatusLoading = false;
  participantsListSuccess = false;
  participantsListLoading = false;

  portionDataList: Array<any> = [];
  portionIsLoading = false;
  portionSuccess = null;
  portionError = null;
  readySuccess = null;
  readyError = null;
  readyIsLoading = null;
  removeParticipantSuccess = false;

  educationStatusInfoData: any = {};
}
