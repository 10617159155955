import i18n from "@/i18n";

export default [
  {
    path: "/set-up-mandate/success",
    name: "MandateSuccess",
    meta: {
      title: String(i18n.t("sidebar.mandate_success")),
      trans: "sidebar.mandate_success",
    },
    component: () => import(/* webpackChunkName: "mandate-success" */ "@/views/Mandate/MandateSuccess.vue"),
  },
  {
    path: "/set-up-mandate/failure",
    name: "MandateFail",
    meta: {
      title: String(i18n.t("sidebar.mandate_fail")),
      trans: "sidebar.mandate_fail",
    },
    component: () => import(/* webpackChunkName: "mandate-fail" */ "@/views/Mandate/MandateFail.vue"),
  },
];
