import Router from "vue-router";
import Vue, { PluginObject } from "vue";

/// Keep-alive-scroll-position-value
const attributeParamName = "data-vue-keep-scroll-position";

const setFcPositionDOM = (el: HTMLElement) => {
  const fcScroller = el.querySelector(".fc-scroller.fc-scroller-liquid-absolute");
  fcScroller?.addEventListener("scroll", (event) => {
    const target = event.target as HTMLElement;
    if (target === undefined) return;
    target.setAttribute(attributeParamName, target.scrollLeft + "-" + target.scrollTop);
  });
};

/// Create custom directive to save scroll position
const configureCustomDirective = () => {
  Vue.directive("keep-scroll-position", {
    bind: function (el) {
      el.addEventListener("scroll", (event) => {
        const target = event.target as HTMLElement;
        if (target === undefined) return;
        target.setAttribute(attributeParamName, target.scrollLeft + "-" + target.scrollTop);
      });
    },
  });

  Vue.directive("fc-keep-scroll-position", {
    inserted: function (el) {
      Vue.nextTick(() => {
        setFcPositionDOM(el);
      });
    },
    componentUpdated: function (el) {
      setFcPositionDOM(el);
    },
  });
};

interface VueKeepScrollPositionPlugin {
  router: Router;
}

const VueKeepScrollPositionPlugin: PluginObject<VueKeepScrollPositionPlugin> = {
  install(vue, option) {
    if (option?.router === undefined) return;
    configureCustomDirective();
    const findAndConfigScrollPosition = () => {
      document.querySelectorAll(`[${attributeParamName}]`).forEach((element) => {
        const offset = element.getAttribute(attributeParamName)?.split("-");
        if (offset === undefined) return;
        element.scrollTop = Number.parseFloat(offset[1]);
        element.scrollLeft = Number.parseFloat(offset[0]);
      });
    };
    option?.router.afterEach(() => vue.nextTick(() => findAndConfigScrollPosition()));
  },
};

export default VueKeepScrollPositionPlugin;
