import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
} from "@/store/Crud/functions/mutations";

import { SET_TASK_PRIORITIES, SET_TASK_GROUPS, SET_TASK_STATUSES } from "@/store/Todo/types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  [SET_TASK_PRIORITIES](state: State, priorities: Array<any>) {
    Object.assign(state, { priorities });
  },
  [SET_TASK_GROUPS](state: State, groups: Array<any>) {
    Object.assign(state, { groups });
  },
  [SET_TASK_STATUSES](state: State, statuses: Array<any>) {
    Object.assign(state, { statuses });
  },
};
