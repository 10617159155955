import { MutationTree } from "vuex";
import { State } from "./state";

export function MESSAGE(state: State, message: string) {
  state.message = message;
}

export function LOADING(state: State, loading: boolean) {
  state.loading = loading;
}

export default <MutationTree<State>>{
  MESSAGE,
  LOADING,
};
