import SessionStorageService from "@/services/SessionStorageService";
import CryptoService from "@/services/CryptoService";
import store from "@/store";
import { IBasicStudent } from "@/interfaces/IBasicStudent";
import { tryParseToJSONObject } from "@/utils/JsonUtil";

export default class UserService {
  public static getUser(): any {
    return this.getFromLocalStorage("user");
  }

  public static getTokenInfo(): any {
    return UserService.getFromLocalStorage("tokenInfo");
  }

  public static getUserSettings(): any {
    const settings = SessionStorageService.getFromLocalStorage("userSettings");
    return tryParseToJSONObject(settings);
  }

  public static getPermissions(): any {
    return this.getFromLocalStorage("permissions") || [];
  }

  public static setPermissions(permissions: Array<string>): any {
    const encode = CryptoService.encode(JSON.stringify(permissions));
    sessionStorage.setItem("permissions", String(encode));
  }

  public static setUserSettings(userSettings: any): any {
    sessionStorage.setItem("userSettings", JSON.stringify(userSettings));
  }

  public static setUser(data: any): void {
    const encode = CryptoService.encode(JSON.stringify(data));
    sessionStorage.setItem("user", String(encode));
  }

  public static setTokenInfo(data: any): void {
    const encode = CryptoService.encode(JSON.stringify(data));
    sessionStorage.setItem("tokenInfo", String(encode));
  }

  public static clearUser(): void {
    SessionStorageService.removeFromLocalStorage("user");
    SessionStorageService.removeFromLocalStorage("token");
    SessionStorageService.removeFromLocalStorage("tokenInfo");
  }

  public static listenLocalStorageAndClearUser(): void {
    SessionStorageService.listenLocalStorage(async (val: StorageEvent) => {
      if ((val.key == "user" || val.key == "token") && val.newValue == null) {
        this.clearUser();
        await store.dispatch("auth/signOut").then(() => window.location.reload());
      }
    });
  }

  public static getRole(): any {
    return this.getUser()?.role_names[0];
  }

  private static getFromLocalStorage(key: string): any {
    const data = sessionStorage.getItem(key);

    if (!data) {
      return null;
    }
    const decode = CryptoService.decode(data.toString());

    if (!decode) {
      return null;
    }
    return JSON.parse(decode);
  }

  public static calendarAccessUsers(): Array<any> {
    return this.getUser()?.calendarAccessUsers || [];
  }

  public static calendarWriteAccessUsers(): Array<IBasicStudent> {
    return this.getUser()?.calendarWriteAccessUsers || [];
  }
}
