import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getStudentQuestionAnswerMetrics = (state: State) => state.studentQuestionAnswerMetrics;
export const getStudentQuestionAnswerMetricsLoading = (state: State) => state.studentQuestionAnswerMetricsLoading;
export const getStudentQuestionAnswerMetricsSuccess = (state: State) => state.studentQuestionAnswerMetricsSuccess;
export const getStudentQuestionAnswerMetricsError = (state: State) => state.studentQuestionAnswerMetricsError;

export const getStudentQuestionAnswerStats = (state: State) => state.studentQuestionAnswerStats;
export const getStudentQuestionAnswerStatsLoading = (state: State) => state.studentQuestionAnswerStatsLoading;
export const getStudentQuestionAnswerStatsSuccess = (state: State) => state.studentQuestionAnswerStatsSuccess;
export const getStudentQuestionAnswerStatsError = (state: State) => state.studentQuestionAnswerStatsError;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getStudentQuestionAnswerMetrics,
  getStudentQuestionAnswerMetricsLoading,
  getStudentQuestionAnswerMetricsSuccess,
  getStudentQuestionAnswerMetricsError,
  getStudentQuestionAnswerStats,
  getStudentQuestionAnswerStatsLoading,
  getStudentQuestionAnswerStatsSuccess,
  getStudentQuestionAnswerStatsError,
};
