import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_SUCCESS } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import i18n from "@/i18n";

const URL = "/driving-lessons";

export const booked = async (store: any, { id, options }: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  const url = `${URL}/${id}/book`;
  const urlWithSign = `${URL}/${id}/upload-student-signature-and-book`;
  const data = options?.data || null;
  let config = {};
  if (data) {
    config = {
      url: urlWithSign,
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  } else {
    config = {
      url,
      method: "post",
      data: {},
    };
  }
  return await axios({ ...config })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      store.dispatch(
        "snackbar/setMessage",
        {
          text: String(
            i18n.t("messages.save_success", {
              resourceName: String(i18n.t("calendar.driving_lesson")),
            })
          ),
          type: "success",
        },
        { root: true }
      );
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, false);
      store.commit(SET_ERROR, error.response?.data);
      store.dispatch(
        "snackbar/setMessage",
        {
          text: String(i18n.t(error.response?.data.error_message)),
          type: "error",
        },
        { root: true }
      );
    });
};

export const deleteDrivingLesson = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${URL}/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  booked,
  deleteDrivingLesson,
};
