import AbibaroService from "@/services/AbibaroService";
export default async (to: any, from: any, next: any) => {
  const hasPermission = await AbibaroService.checkAbibaroRegistration();
  if (hasPermission) {
    next();
    return true;
  }
  next({ path: from.path });

  return false;
};
